@mixin opensans {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
}

@mixin lora {
  font-family: 'Lora', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  font-style: italic;
}

@mixin montserrat {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  letter-spacing: -0.61803px;
  word-spacing: .25rem;
}

@mixin c-main {
  color: $c-main;
}

@mixin bc-main {
  background-color: $c-main;
}

@mixin c-contrast {
  color: $c-contrast;
}

@mixin bc-contrast {
  background-color: $c-contrast;
}

@mixin c-alt {
  color: $c-alt;
}

@mixin bc-alt {
  background-color: $c-alt;
}

@mixin c-green {
  color: $c-green;
}

@mixin bc-green {
  background-color: $c-green;
}

@mixin c-red {
  color: $c-red;
}

@mixin bc-red {
  background-color: $c-red;
}

@mixin c-light {
  color: $c-light;
}

@mixin bc-light {
  background-color: $c-light;
}

@mixin c-white {
  color: $c-white;
}

@mixin bc-white {
  background-color: $c-white;
}

@mixin c-black {
  color: $c-black;
}

@mixin bc-black {
  background-color: $c-black;
}

@mixin c-livros {
  color: $c-livros;
}

@mixin bc-livros {
  background-color: $c-livros;
}

@mixin c-questoes {
  color: $c-questoes;
}

@mixin bc-questoes {
  background-color: $c-questoes;
}

@mixin c-destaques {
  color: $c-destaques;
}

@mixin bc-destaques {
  background-color: $c-destaques;
}

@mixin c-noticias {
  color: $c-noticias;
}

@mixin bc-noticias {
  background-color: $c-noticias;
}

@mixin c-depoimentos {
  color: $c-depoimentos;
}

@mixin bc-depoimentos {
  background-color: $c-depoimentos;
}

@mixin c-cursos {
  color: $c-cursos;
}
@mixin c-cadernos-de-questoes {
  color: $c-cadernos-de-questoes;
}

@mixin bc-cursos {
  background-color: $c-cursos;
}

@mixin c-apostilas {
  color: $c-apostilas;
}

@mixin bc-apostilas {
  background-color: $c-apostilas;
}

@mixin c-erratas {
  color: $c-erratas;
}

@mixin bc-erratas {
  background-color: $c-erratas;
}

@mixin c-provas {
  color: $c-provas;
}

@mixin bc-provas {
  background-color: $c-provas;
}

@mixin sombra {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
}

@mixin sombra-sm {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
}

@mixin sombra-lg {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.05), 0 9px 46px 8px rgba(0, 0, 0, 0.05), 0 11px 15px -7px rgba(0, 0, 0, 0.08);
}

@mixin anima {
  // transition: all .24s cubic-bezier(.0, .0, .58, 1.0);
  transition: all .4s cubic-bezier(.160, .560, .170, .999);
  // transform: translate3d(0, 0, 0) translateZ(0);
  // backface-visibility: hidden;
  // will-change: transform;
}

@keyframes animeDropdown {
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
}

@mixin animeDropdown {
  animation: animeDropdown 0.36s 0s normal both;
  animation-timing-function: cubic-bezier(.160, .560, .170, .999);
}

@keyframes animeStickyHeader {
  from {
    transform: translateY(-4rem) translateZ(-500px);
    opacity: 0;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.07), 0 9px 46px 8px rgba(0, 0, 0, 0.06), 0 11px 15px -7px rgba(0, 0, 0, 0.1);
  }
}

@mixin animeStickyHeader {
  animation: animeStickyHeader 0.618s 0s normal both;
  animation-timing-function: cubic-bezier(.160, .560, .170, .999);
}

@mixin box-card {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  @include anima;
  padding: 1rem;

  &:hover {
    box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
  }
}

@mixin input {
  @include anima;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 0 0 0 1px #ddd, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  }

  &:focus {
    box-shadow: 0 0 0 2px $c-alt, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  }
}

@mixin box-card-account {
  @include box-card;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 8rem;
  border-radius: 5px;
  line-height: 1;

  @media (min-width:768px) {
    &.-large {
      padding: 2rem;
    }
  }
}

@mixin clearfix {

  &::before,
  &::after {
    display: table;
    content: " ";
  }

  &::after {
    clear: both;
  }
}