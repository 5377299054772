@charset 'UTF-8';

//
// _____________________________________________________________________
// Base do tema

body {
  @include opensans;
  // @include bc-light;
  font-size: 1rem;

  @media (min-width: 0) and (max-width: 576px) {
    line-height: 1.5;
  }

  @media (min-width: 577px) and (max-width: 767px) {
    line-height: 1.4;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    line-height: 1.3;
  }

  @media (min-width: 993px) {
    line-height: 1.2;
    font-size: 14px;
  }
}

html .section {
  @media (min-width: 0) and (max-width: 576px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @media (min-width: 993px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

//
// _____________________________________________________________________
// Titulos

.title-dash {
  position: relative;
  display: inline-block;
  z-index: 1;

  &::before {
    position: absolute;
    width: calc(100% + 10px);
    left: -5px;
    right: -5px;
    top: 50%;
    content: " ";
    z-index: -1;
    border-top-width: 4px;
    border-top-style: solid;
  }

  &.-livros::before {
    @include c-livros;
  }

  &.-questoes::before {
    @include c-questoes;
  }

  &.-destaques::before {
    @include c-destaques;
  }

  &.-noticias::before {
    @include c-noticias;
  }

  &.-depoimentos::before {
    @include c-depoimentos;
  }

  &.-cursos::before {
    @include c-cursos;
  }
  &.-c-cadernos-de-questoes::before {
    @include c-cadernos-de-questoes;
  }

  &.-provas::before {
    @include c-provas;
  }

  &.-erratas::before {
    @include c-erratas;
  }
}

.section-hero {
  text-align: center;

  @media (min-width: 0) and (max-width: 576px) {
    margin-bottom: 1rem;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    margin-bottom: 1.5rem
  }

  @media (min-width: 993px) {
    margin-bottom: 2rem
  }
}

.section-title {
  margin-bottom: 0;
  color: #232323;
  font-weight: bold;

  &.-main {
    color: $c-main;
  }

  @media (min-width: 0) and (max-width: 576px) {
    font-size: 1.75rem;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    font-size: 2.25rem;
  }

  @media (min-width: 993px) {
    font-size: $fs-section-title;
  }
}

.section-subtitle {
  @include lora;
  color: $c-mutted;

  @media (min-width: 0) and (max-width: 576px) {
    font-size: 1rem;
  }

  @media (min-width: 577px) and (max-width: 767px) {
    font-size: 1.25rem;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 1.5rem;
  }

  @media (min-width: 993px) {
    font-size: $fs-section-subtitle;
  }
}

//
// _____________________________________________________________________
// Tema

.main-content {
  background-image: url(../img/bg-page.png);
  background-position: left top;
  background-repeat: no-repeat;
}

.dropdown-menu.show {
  @include sombra;
}

.box-card {
  @include box-card;
}

//
// _____________________________________________________________________
// Botoes

#html {

  .btn-light {
    color: #444;
  }

  .btn-grey {
    color: #444;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.btn-border-bottom {
  box-shadow: inset 0 -2px 0 0 $c-noticias;
  border-radius: 0 !important;

  &:hover,
  &:active {
    @include bc-noticias;
    color: white;
  }
}

#html .float-button-whatsapp {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1020;
  padding: 0;
  border-radius: 50%;
  width: 3.3rem;
  height: 3.3rem;
  @include anima;

  @media (min-width: 0) and (max-width: 576px) {
    margin: 1rem;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    margin: 1.5rem;
  }

  @media (min-width: 993px) {
    margin: 2rem;
  }

  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .1),
  0 1px 15px 0 rgba(0, 0, 0, .16),
  0 2px 4px -1px rgba(0, 0, 0, .1);

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
  }

  &:focus {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
  }

  &:hover {
    transform: translateY(-3px);
  }
}

.main-content .nav-item {

  &:hover,
  &.active {
    @include c-main;
  }
}

.nav-item {

  &.-cursos-online {

    &:hover,
    &.active {
      @include c-cursos;
    }
  }

  &.-cadernos-de-questoes {

    &:hover,
    &.active {
      @include c-cadernos-de-questoes;
    }
  }

  &.-apostilas {

    &:hover,
    &.active {
      @include c-apostilas;
    }
  }

  &.-livros {

    &:hover,
    &.active {
      @include c-livros;
    }
  }

  &.-questoes-de-concursos {

    &:hover,
    &.active {
      @include c-questoes;
    }
  }

  &.-provas {

    &:hover,
    &.active {
      @include c-provas;
    }
  }

  &.-noticias {

    &:hover,
    &.active {
      @include c-noticias;
    }
  }

  &.-erratas-e-redificacao {

    &:hover,
    &.active {
      @include c-erratas;
    }
  }
}

.hr-divider {
  display: flex;
  align-itemPixes: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;
    height: 1px;
    background-color: #ddd;
  }

  span {
    @include bc-light;
    padding: 0.1rem 0.5rem;
    text-transform: uppercase;
  }
}

//
// _____________________________________________________________________
// budget filtro de produto

.form-product-filter {
  float: right;
  position: relative;

  .form-row {
    align-itemPixes: center;
    flex-flow: row nowrap;

    @media (max-width: 767px) {
      justify-content: flex-start;
    }

    @media (min-width: 768px) {
      justify-content: flex-end;
    }
  }

  .form-group {
    margin-bottom: 0;

    & + .form-group {
      margin-left: 0.75rem;
    }
  }

  .btn-link {
    padding: 0 0.5rem;
    color: inherit;
  }

  select.form-control {
    max-width: 8rem;

    @media (max-width: 767px) {
      padding: 0 0.5rem;
    }
  }
}

.form-group.-product-filter {
  position: relative;
  white-space: nowrap;

  .label-product-filter {
    display: flex;
    align-itemPixes: center;
    padding: 0;
    cursor: pointer;
    margin: 0;
    padding: 0.5rem 0;

    img {
      vertical-align: top;
      margin-right: 0.5rem;
    }

    .fa {
      margin-left: 0.5rem;
    }
  }

  .form-control {
    position: absolute;
    opacity: 0;
    z-index: 2;
    padding: 0;

    &:focus {
      opacity: 1;
    }
  }
}

//
// _____________________________________________________________________
// Slider da home - Revolution

#homeSlider {
  color: white;
  text-align: center;

  .title-dash {
    &::before {
      border-top-width: 8px;
    }
  }

  .title {
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    @media (min-width: 0) and (max-width: 768px) {
      font-size: 2rem;
    }

    @media (min-width: 993px) {
      font-size: 3.75rem;
    }
  }

  .subtitle {
    line-height: 1.5;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.333);
    font-size: 1.3rem;

    @media (min-width: 0) and (max-width: 768px) {
      white-space: normal;
      min-width: 90vw;
      margin-top: 2rem;
      line-height: 1.2;
    }
  }
}

//
// _____________________________________________________________________
// Header

.main-header {
  @include bc-light;

  &.is-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    @include animeStickyHeader;

    .header-middle-bar {
      padding: 0.5rem 0;
      background-color: white !important;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.03), 0 4px 0 0 rgba(0, 0, 0, 0.02);
    }

    .logo {
      max-height: 3rem !important;
    }

    @media (min-width: 0) and (max-width: 992px) {

      .menu-search,
      .menu-ecommerce,
      .header-top-bar,
      .header-bottom-bar {
        display: none !important;
      }
    }

    @media (min-width: 993px) {

      .header-top-bar,
      .header-bottom-bar {
        display: none !important;
      }
    }
  }
}

.header-top-bar {
  @include bc-main;
  color: white;
  font-size: 12px;

  .navbar-nav {
    align-itemPixes: center;

    a {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .contact-list {
  }

  .social-list {

    a {
    }

    .fa {
      font-size: 1.25rem;
    }

    .nav-item.-whatsapp {
      @include c-green;
      margin-right: 1rem;
    }
  }
}

.header-middle-bar {
  // background-color: white;

  @media (max-width: 991px) {
    padding: 0.5rem 0;
  }

  @media (min-width: 992px) {
    padding: 1rem 0;
    background-color: transparent;
  }

  .main-menu-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-itemPixes: center;
  }

  .menu-logo {
    align-self: flex-start;
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-itemPixes: center;

    .logo {
      max-height: 3rem;
      width: auto;
    }

    @media (min-width: 992px) {
      width: auto;

      .logo {
        max-height: none;
        max-width: 128px;
      }
    }
  }

  .btn-toggle-mobilemenu {
    line-height: 3rem;
    height: 3rem;
    padding: 0;
    border-radius: 0;
    text-align: center;
    background-color: transparent;

    &::after {
      content: "Menu";
      display: inline-block;
      @include c-main;
      font-weight: bold;
      margin-left: 0.5rem;
    }

    img {
      width: 2rem;
    }
  }

  .btn-main-menu {
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    @include anima;

    &:hover {
      box-shadow: 0 0 0 1px #ddd, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }

    &:focus {
      box-shadow: 0 0 0 2px $c-alt, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }

    &::after {
      display: none;
    }
  }

  .menu-hamburguer {
    align-itemPixes: center;

    .dropdown-menu {
      font-size: 13px;
      line-height: 1;
      min-width: 12rem;
      margin-top: 1rem;

      &::after,
      &::before {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &::after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
      }

      &::before {
        border-color: rgba(238, 238, 238, 0);
        border-bottom-color: #efefef;
        border-width: 13px;
        margin-left: -13px;
      }

      .nav-link:hover {
        background-color: $c-main;
        color: white;
      }

    }

    strong.c-main {
      display: inline-block;
      margin-left: 0.25rem;
    }
  }

  .menu-search {
    position: relative;

    @media (max-width: 991.98px) {
      width: 100%;
      margin: 1rem 0;
    }

    .form-control {
      width: 100%;
      line-height: 2.5rem;
      height: 2.5rem;

      @media (min-width: 992px) {
        min-width: 400px;
      }
    }

    .label {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0.75rem 1rem;
      line-height: 1;
      margin: 0;
    }
  }

  .btn-wishlist {
    font-weight: 700;
    font-weight: bold;
    display: flex;
    align-itemPixes: center;
    white-space: nowrap;

    .fa {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  .menu-ecommerce {
    display: flex;
    align-itemPixes: center;

    .item {

      & + .item {
        margin-left: 2rem;
      }
    }

    @media (max-width: 991.98px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .btn-login {
      // line-height: 2.75rem;
      // height: 2.75rem;
      display: flex;
      align-itemPixes: center;

      img {
        margin-right: 0.35rem;
      }
    }

    .btn-cart {
      position: relative;
      cursor: pointer;
      display: inline-block;

      @media (max-width: 991.98px) {
        margin: 0 1rem 0 0;
      }

      &:hover {

        img {
          opacity: 0.8;
        }

        .badge {
          top: -7px;
        }
      }

      img {
        width: 2.25rem;
      }
    }

    .badge {
      border-radius: 1rem;
      position: absolute;
      top: -5px;
      left: 100%;
      transform: translateX(-50%);
      box-shadow: 0 0 0 3px $c-light;
      line-height: 1.25rem;
      width: 1.25rem;
      padding: 0;
    }
  }
}

.header-bottom-bar {
  background-color: white;
  // box-shadow: 0 -1px 0 0 #eee;

  .main-navigation {
    justify-content: space-between;
    padding: 0;
    font-weight: 600;

    .nav-link {
      position: relative;
      border-bottom: 2px solid transparent;
      border-top: 2px solid transparent;
      padding: 0 1rem;
      line-height: 2.5rem;

      &:hover,
      &.active {
        border-top-color: inherit;
      }
    }

    .nav-item.-home a::before {
      margin-right: 7px;
      content: url(../img/svg-institucional.svg);
      line-height: 1;
      vertical-align: middle;
      display: inline-block;
      position: relative;
      top: -3px;
    }

    .nav-item.-home:not(:hover) a::before {
      filter: grayscale(1);
    }
  }
}

//
// _____________________________________________________________________
// Footer

.main-footer {
  font-size: 13px;
  background-color: $c-light;
  // box-shadow: inset 0 1px 0 #eee;
  // background: white;
  //position: absolute;
  position: relative;
  width: 100%;
  bottom: 0;


  .link-ilion {

    &:hover {
      .logo-ilion {
        transform: translateY(-2px);
      }
    }
  }

  .logo-ilion {
    margin-top: 3rem;
  }
}

.institucional-bar {

  .title.-footer {
    font-size: 1rem;
    @include c-main;

    img {
      margin-right: 0.5rem;
      vertical-align: bottom;
    }
  }

  .list {
    color: #888;
    font-weight: 600;

    @media (max-width: 991.98px) {
      margin-bottom: 3rem;
    }

    @media (min-width: 992px) {
      padding-left: 2rem;
    }

    .nav-link {
      padding: 0;
    }

    &.-social {
      padding: 0;
      justify-content: flex-start;

      .nav-link {
        padding: 0 0.5rem;
        font-size: 2rem;
      }
    }

    .footer-horario {
      line-height: 1.5;
      margin-top: 2rem;

      strong {
        @include c-main;
        display: block;
      }
    }
  }

  .footer-alerta {
    display: flex;
    align-itemPixes: center;

    strong {
      @include c-contrast;
    }

    img {
      margin-right: 1rem;
    }
  }

  .form-newsletter.-footer {
    margin-top: 3rem;
    position: relative;

    .form-control {
      width: 100%;
      height: 2.75rem;
      line-height: 2.75rem;
      padding-right: 3rem;
    }

    .btn-submit {
      position: absolute;
      right: 0;
      top: 0;
      height: 2.75rem;
      line-height: 2.75rem;
      background-color: transparent;

      &:hover {
        @include bc-main;
        color: white;
      }
    }
  }

  .footer-site-seguro {

    @media (max-width: 991.98px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
}

.copyright-bar {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include bc-main;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;

  strong {
    color: white;
  }

  .row {
    align-itemPixes: center;
  }

  .copyright-company {
    @media (max-width: 991.98px) {
      margin-bottom: 2rem;

      figure {
        margin-bottom: 1rem;
      }
    }

    @media (min-width: 992px) {
      display: flex;
    }

    figure {
      margin-right: 2rem;
    }
  }

  .footer-address strong {
    @media (max-width: 991.98px) {
      display: block;
      margin-top: 2rem;
    }
  }

  .list-copyright-contacts {
    white-space: nowrap;
  }

  .footer-address {
    font-weight: inherit;
  }
}

//
// _____________________________________________________________________
// Titulo da página

.page-title {
  background-color: white;
  padding-top: 1rem;
  // padding-bottom: 1rem;

  // @media (min-width: 768px) {
  //   padding-bottom: 2rem;
  // }

  .breadcrumb {
    background-color: inherit;
    padding: 0 0 1rem 0;
    margin: 0;
    line-height: 1;

    li {
      display: flex;
      align-itemPixes: center;
      flex-flow: row nowrap;
    }

    h1 {
      font-size: inherit;
      display: inline-block;
    }
  }

  .title {
    font-weight: bold;
    font-size: 2rem;
    color: #232323;
    margin-bottom: 0;
    line-height: 1;

    &.-main {
      @include c-main;
    }
  }

  .subtitle {
    color: #aaa;
    font-weight: 600;
    margin-top: 0.5rem;
    line-height: 1;
  }

  .form-product-filter {

    @media (max-width: 767px) {
      margin-top: 1rem;
    }
  }

  .hr-page-title {
    margin-bottom: 0;
    background-color: #eeeeee;
    display: none;
  }

  .container {
    &:last-child {
      &::after {
        content: " ";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #eeeeee;
        clear: both;
        overflow: hidden;
        margin-top: 1rem;

        @media (min-width: 768px) {
          margin-top: 2rem;
        }
      }
    }
  }
}

//
// _____________________________________________________________________
// Produtos

.listagem-produto {

  &.carousel {

    .product {
      //box-shadow: none !important;
    }

    .slick-slide {
      @media (min-width: 0) and (max-width: 576px) {
        padding: 0.5rem;
      }
    }
  }

  & > li {
    @media (min-width: 0) and (max-width: 576px) {
      padding: 1rem 3rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      padding: 1rem;
    }

    @media (min-width: 993px) {
      padding: 1rem;
    }
  }

  .product {
    @include box-card;

    @media (min-width: 993px) {
      height: 100%;
    }

    .product-embed {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden;

      &::before {
        padding-top: 100%;
        display: block;
        content: " ";
      }

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-itemPixes: center;
        justify-content: center;
      }

      .img-product {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    .title.-name {
      font-size: 1rem;
      color: #242424;
      margin: 1.5rem 0;
      word-break: break-word;
    }

    .product-actions {
      display: flex;
      justify-content: space-between;

      .btn {
        border-radius: 1rem;

        &.btn-price {
          color: $c-red;
          font-size: 1rem;
        }
      }
    }

    .btn-type {
    }

    .btn-price {
    }

    //CURSO ONLINE
    &.-curso-online {
      background-color: #f8f8f8;
      @include bc-light;
      box-shadow: none;
      padding: 0;

      .product-embed {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &::before {
          padding-top: 56.25%;
        }

        .img-product {
          width: 100%;
          max-width: none;
          max-height: none;
        }
      }

      .product-info {
        padding: 1.5rem;

        .title {
          margin: 0;
        }
      }

      .product-price {
        @include c-red;
        font-size: 1rem;
        margin-top: 1rem;
        line-height: 1.1;
        font-weight: 600;
      }
    }
  }
}

.produtos-relacionados {
  box-shadow: inset 0 1px 0 #eee;
}

//
// _____________________________________________________________________
// Produto - Curso
.product {
  position: relative;

  .title.-name {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
    line-height: 1.1;
  }

  .product-price {
    @include c-contrast;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.1;

    small {
      font-weight: 500;
    }
  }

  .btn-buy {
    display: flex;
    justify-content: space-between;
    align-itemPixes: center;

    img {
      width: 1.25rem;
    }
  }

  .btn-wishlist {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem;
    background-color: transparent;
    color: $c-red;
    z-index: 10;
    padding: 0;
    font-size: 1.5rem;

    &:hover .fa {
      &::before {
        content: "\f004";
      }
    }
  }

  .product-payment-method {
  }

  .product-article {
    margin-top: 3rem;

    .title {
      font-size: 1.25rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
    }
  }

  .form-product-buy {

    fieldset {
      padding: 2rem;
      @include bc-light;
      border-radius: 5px;
      color: #999;
      line-height: 1;
    }

    .btn-buy {
      line-height: 4rem;
      height: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 1.25rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .variations {

    .variation {
      position: relative;
      padding-left: 2rem;
      margin-bottom: 2rem;

      &:hover {

        .fa {
          &::before {
            content: "\f046";
          }
        }
      }

      label {
        margin: 0;
        cursor: pointer;
        display: block;
      }

      .product-price {
        margin-bottom: 0;
      }

      .fa {
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 1.5rem;
        line-height: 0.8090;
        @include anima;
      }

      input:checked + .fa {
        @include c-green;

        &::before {
          content: "\f046";
        }
      }
    }
  }

  .list-share {
    display: flex;
    justify-content: center;
    align-itemPixes: center;
    font-weight: 600;
    margin-top: 1rem;

    .fa {
      font-size: 1.5rem;
    }
  }

  .product-list-features {
    display: flex;
    flex-wrap: wrap;

    .feature {

      .title {
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: bold;
      }

      .subtitle {
        line-height: 1.3;
      }
    }
  }

  .product-showcase {

    .title {
      font-size: 1.75rem;
      color: #232323;
      margin-bottom: 2rem;
      font-weight: bold;
    }

    table {

      td {
        padding: 0.25rem 1rem;

        &:first-child {
          width: 25%;
        }
      }
    }
  }

  //
  // CURSO
  &.curso {

    .embed-responsive {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

}

//
// _____________________________________________________________________
// Noticias

.listagem-noticia {
  & > li {
    @media (min-width: 0) and (max-width: 576px) {
      padding: 1rem 3rem;
    }
  }
}

.news {

  figcaption {
    @include box-card;
  }

  .news-figure {
  }

  .news-embed {
  }

  .news-img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    z-index: auto;
  }

  .news-title {
    margin: 0.5rem 0;
    word-break: break-word;
    color: #242424;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-line-clamp: 3;

    @media (min-width: 0) and (max-width: 576px) {
      font-size: 1rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      font-size: 1.15rem;
    }

    @media (min-width: 993px) {
      font-size: 1.125rem;
    }
  }

  .news-datetime {
  }

  .news-description {
  }
}

//
// _____________________________________________________________________
// Depoimentos

.depoimentos.-home {

  .section-title .title-dash {

    &::after {
      display: inline-block;
      content: url(../img/svg-aspas.svg);
      position: absolute;
      margin: -1.5rem 1rem;
    }
  }

  .container-fluid {
    padding: 0;
  }

  .slick-slide {
    padding: 0;

    &.slick-current {
      .depoimento-caption {
        background-color: rgba($c-alt, .95);
      }
    }
  }
}

.listagem-depoimento {

  .slide-control {

    &.-prev {
      left: 0;
      right: auto;
    }

    &.-next {
      right: 0;
      left: auto;
    }
  }
}

.depoimento {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;

  &::before {
    padding-top: 100%;
    display: block;
    content: " ";
  }

  &:hover {

    .depoimento-caption {
      background-color: rgba($c-alt, .95);
    }
  }

  .depoimento-caption {
    background-color: rgba($c-main, .95);
    color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    @include anima;

    @media (min-width: 0) and (max-width: 576px) {
      width: 80%;
      padding: 1rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      width: 80%;
      padding: 1.5rem;
    }

    @media (min-width: 993px) {
      width: 60%;
      padding: 2rem;
    }
  }

  .depoimento-img {
  }

  .depoimento-title {
    font-weight: bold;

    @media (min-width: 0) and (max-width: 576px) {
      font-size: 1.5rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      font-size: 1.75rem;
    }

    @media (min-width: 993px) {
      font-size: 2rem;
    }

    small {
      display: block;
    }
  }

  .depoimento-subtitle {
  }

  .depoimento-desc {
  }
}

//
// _____________________________________________________________________
// Review / Avaliacoes

.avaliacoes {
  box-shadow: inset 0 1px 0 #eee;

  .listagem-avaliacoes {
  }

  .item-avaliacao {

    & + .item-avaliacao {
      margin-top: 1rem;

      @media (min-width: 577px) {
        margin-top: 2rem;
      }
    }
  }

  .avaliacao {
  }

  .avaliacao-autor {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .avaliacao-estrelas {
    margin-bottom: 0.5rem;

    .fa {
      font-size: 0.85rem;
    }
  }

  .avaliacao-texto {
  }
}

//
// _____________________________________________________________________
// Rating

.review-rating {
  color: #eee;
  display: flex;
  line-height: 1;
  font-size: 1.15rem;

  .fa {

    & + .fa {
      padding-left: 0.25rem;
    }

    &.-checked {
      color: #fde396;
    }
  }

}

//
// _____________________________________________________________________
// Institucional

.institucional {
  position: relative;
  overflow: hidden;

  &.quem-somos {

    .col-bg-image {
      background-image: url(../img/quem-somos-bg.jpg);

      img {
        opacity: 0;
      }
    }
  }

  &.sac {

    .col-bg-image {
      background-image: url(../img/sac-bg.jpg);
    }
  }

  &.login {

    .col-bg-image {
      background-image: url(../img/login-bg.jpg);
    }
  }

  .col-bg-image {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    height: 100%;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .article {
    font-size: 1rem;
    min-height: 66.666678vh;

    .title {
      font-size: 2rem;
      @include c-main;
      @include montserrat;
      margin-bottom: 2rem;
      font-weight: bold;

      @media (min-width: 993px) {
        font-size: 42px;
      }
    }

    .article-footer {
      display: flex;
      flex-flow: row nowrap;
      white-space: nowrap;
      align-itemPixes: center;
      margin-top: 3rem;

      .btn {
        display: flex;
        flex-flow: row nowrap;
        align-itemPixes: center;
        justify-content: center;

        .fa {
          margin-right: 0.5rem;
          font-size: 1.5rem;
        }

        & + .btn {
          margin-left: 1.5rem;

          .fa {
            margin-left: 0.5rem;
            margin-right: 0;
          }
        }
      }
    }

    .list-buttons {
      display: flex;
      flex-flow: row nowrap;
      white-space: nowrap;
      align-itemPixes: center;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .btn {
        display: flex;
        flex-flow: row nowrap;
        align-itemPixes: center;
        justify-content: space-between;
        min-width: calc(50% - 0.5rem);
        font-size: 14px;

        & + .btn {
          margin-left: 1rem;
        }

        .fa {
          font-size: 1.5rem;
        }

      }

      .btn-login {
        background-color: white;
        color: #aaa;
        font-size: 12px;
        @include input;

        img {
          max-width: 1.5rem;
          max-height: 1.5rem;
          border-radius: 3px;
        }
      }
    }
  }

  .accordion {

    .card {
      padding: 1rem 0;
      background: transparent;
      box-shadow: 0 -1px 0 0 #ddd;
      border-radius: 0;

      &:last-child {
        border-bottom: 1px solid #ddd;
      }

      .card-header {
        padding: 0;
        background: transparent;
      }

      .card-body {
        padding: 1rem 0 0 0;
      }

      .cart-title {
        font-size: 1rem;
        @include c-main;
        font-weight: bold;
      }
    }
  }
}

//
// _____________________________________________________________________
// Login

.form-login {

  .form-group {
    margin-bottom: 1.5rem;
  }
}

//
// _____________________________________________________________________
// Video Aulas

.listagem-videoaulas {

  .card {

    .card-header {
      color: white;
      @include bc-main;
      border-radius: 5px !important;
    }

    .cart-title {
      font-size: 1rem;
    }
  }

  .videoaula {
  }

  .listagem-aulas {
    padding: 0;

    .aula {
      @include bc-light;
      display: flex;
      align-itemPixes: center;

      @media (min-width: 0) and (max-width: 576px) {
        padding: 0.5rem 1rem;
      }

      @media (min-width: 577px) {
        padding: 1rem;
      }

      img {
        margin-right: 1rem;
      }

      & + .aula {
        border-top: 1px solid #eee;
      }
    }
  }
}

//
// _____________________________________________________________________
// Noticias

.noticia-section {
  border-top: 1px solid #eee;
}

.noticias {

  .destaques {
    margin-bottom: 3rem;

    .noticia {
      @media (min-width: 0) and (max-width: 576px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    .datetime {
      line-height: 1;
      margin-bottom: 0.5rem;

      time {
        display: inline-block;
        background-color: white;
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        color: #333;
      }
    }

    .title {
      font-weight: bold;
      margin-top: 0;
    }

    .description {
    }

    .noticia-destaque {

      .media-body {
        padding: 2rem;
        background-image: linear-gradient(180deg, transparent 40%, rgba(0, 0, 0, .6));
      }

      .title {
        margin-bottom: 0;

        @media (min-width: 0) and (max-width: 576px) {
          margin-top: 1.25rem;
        }

        @media (min-width: 577px) {
          margin-top: 1.25rem;
        }
      }

      .media {
        &:hover {

          .title {
            color: inherit;
            margin-bottom: 1rem;
          }

          .description {
            display: block;
          }
        }
      }

      .description {
        @include animeStickyHeader;
        display: none;
      }
    }

    .noticia-aside-destaque {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .media-body {
        padding: 1rem;
        background-image: linear-gradient(180deg, transparent 60%, rgba(0, 0, 0, .6));
      }

      .title {
        font-size: 1rem;
        margin: 0;
      }

      .description {
        display: none;
      }

      .media {
        &:hover {

          .title {
            color: inherit;
          }
        }
      }
    }

    .media {
      display: block;
      position: relative;

      overflow: hidden;
      border-radius: 5px;

      .embed-responsive {
        z-index: 0;
        background-color: #232323;

        img {
          opacity: 0.8613;
        }
      }

      .media-body {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        z-index: 2;
        color: white;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
      }
    }
  }
}

//
// _____________________________________________________________________
// Noticia

.noticia {
  .embed-responsive {
    border-radius: 5px;
    overflow: hidden;
  }

  .datetime {
    margin-bottom: 0.25rem;
    line-height: 1;
  }

  a {
    text-decoration: none;

    &:hover {
      .title {
        @include c-alt;
      }

      .img-noticia {
        transform: scale(1.05);
      }
    }
  }

  &.-main {

    .title {
      font-size: 2.5rem;
      margin-top: 0;
      color: #232323;
      font-weight: bold;
      line-height: 1.16381;
      margin-bottom: 2rem;
    }

    .article-body {
      margin-top: 2rem;

      p {
        font-size: 1.25rem;
        margin-bottom: 0;
        margin-top: 2rem;
      }
    }
  }
}

.listagem-noticias {

  .noticia-item {
    @include box-card;

    & + .noticia-item {

      @media (min-width: 0) and (max-width: 576px) {
        margin-top: 1.5rem;
      }

      @media (min-width: 577px) {
        margin-top: 2rem;
      }
    }
  }

  .embed-responsive {
    border-radius: 5px;
    overflow: hidden;

    @media (min-width: 577px) {
      max-width: 33.33334%;
    }
  }

  .img-noticia {
  }

  .media {
    @media (min-width: 0) and (max-width: 576px) {
      flex-flow: column nowrap;
    }
  }

  .media-body {
    @media (min-width: 0) and (max-width: 576px) {
      padding-left: 0;
    }

    @media (min-width: 577px) {
      padding-left: 1.5rem;
    }
  }

  .datetime {
    color: $c-mutted;
    font-size: small;
    line-height: 1;

    @media (min-width: 0) and (max-width: 576px) {
      margin-top: 0.5rem;
    }
  }

  .title {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.1683;
    @include anima;
  }

  .description {
  }
}

.aside-noticias {
  @media (min-width: 0) and (max-width: 576px) {
    margin-top: 3rem;
  }

  .title-aside {
    text-transform: uppercase;
    font-weight: bold;
    color: #232323;
    margin-bottom: 1.5rem;

    @media (min-width: 0) and (max-width: 576px) {
      font-size: 1.25rem;
      padding-top: 1rem;
      text-align: center;
    }

    @media (min-width: 577px) {
      font-size: 1.1rem;
    }
  }

  .listagem-aside {
    margin-bottom: 2rem;
    @include box-card;

    & + .listagem-aside {
      margin-top: 2rem;
    }

    .noticia-item {
      & + .noticia-item {
        border-top: 1px solid #eee;
        margin-top: 1.5rem;
        padding-top: 1.5rem;
      }
    }

    .embed-responsive {
      display: none;
    }

    .title {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.1683;
      @include anima;
    }

    .datetime {
      color: $c-mutted;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 0.25rem;
    }

    .description {
      display: none;
    }
  }
}

//
// _____________________________________________________________________
// Erratas

.listagem-erratas {

  .errata-item {

    & + .errata-item {
      padding-top: 3rem;
      margin-top: 3rem;
      border-top: 1px solid #eee;
    }
  }

  .errata {
    align-itemPixes: center;

    .fa {
      font-size: 4rem;
      margin-right: 2rem !important;
    }

    .title {
      font-size: 1.5rem;
      color: #232323;
      margin-bottom: 0;
    }

    .description {
      margin-bottom: 0.5rem;
    }

    .datetime {
      color: $c-mutted;
      line-height: 1;
    }

    a:hover {
      @include c-erratas;

      .title {
        color: inherit;
      }
    }
  }
}

.errata-item {

  &:hover {
    @include c-erratas;

    .title {
      color: inherit;
    }
  }

  & + .errata-item {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid $c-light;
  }

  .datetime {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}

.errata {

  .title {
    font-size: 2rem;
    color: #232323;
    line-height: 1.2;
    margin-top: 0;
    font-weight: bold;
  }

  .article-body {
    margin-top: 2rem;

    p {
      font-size: 1.15rem;
      margin-bottom: 0;
      margin-top: 2rem;
    }
  }
}

//
// _____________________________________________________________________
// Planos

.planos {

  .col-12 {
    @media (min-width: 0) and (max-width: 576px) {
      padding: 1rem;
    }
  }

  .row {
    justify-content: center;
  }

  .plano {
    @include box-card;

    &.-destaque {
      border: 2px solid $c-contrast;
    }

    &:hover {
      transform: translateY(-3px);
    }

    .plano-header {
      text-align: center;
    }

    .title {
      @include montserrat;
      font-size: 1.5rem;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 3rem;
      margin-top: 1rem;
      color: #232323;
    }

    .description {
      margin: 0;
      line-height: 1;
    }

    .price {
      margin-top: 0;
      font-size: 1rem;
      line-height: 1;

      strong {
        font-size: 3.5rem;
      }
    }

    .plano-body {
      margin: 2rem 0;
    }

    .listagem-features {
    }

    .feature {
      display: flex;
      align-itemPixes: center;

      & + .feature {
        margin-top: 0.5rem;
      }

      &.-disabled {
        color: $c-mutted;
        text-decoration: line-through;

        .fa {
          color: inherit;
          text-decoration: none;

          &::before {
            content: "\f00d";
          }
        }
      }

      .fa {
        @include c-green;
        width: 2rem;
      }
    }
  }
}

#html .btn-planos {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1020;
  color: white;
  @include anima;

  @media (min-width: 0) and (max-width: 576px) {
    margin: 1rem 6rem;
  }

  @media (min-width: 577px) and (max-width: 992px) {
    margin: 1.5rem 7rem;
  }

  @media (min-width: 993px) {
    margin: 2rem 7rem;
  }

  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .1),
  0 1px 15px 0 rgba(0, 0, 0, .16),
  0 2px 4px -1px rgba(0, 0, 0, .1);

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
  }

  &:focus {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
  }

  &:hover {
    transform: translateY(-3px);
  }
}

//
// _____________________________________________________________________
// Questões de Concurso

.questions-filter {
  @include bc-light;
  padding-top: 2rem !important;
  padding-bottom: 1rem !important;

  .form-row {
    align-itemPixes: center;
    margin: 0;

    @media (max-width: 575.98px) {
      display: block;

      .form-group {
        padding: 0;
      }
    }

    & + .form-row {
      margin-top: 1.5rem;
    }
  }

  .title {
    font-size: 1rem;
    font-weight: bold;
    color: #232323;
    margin: 0 1rem 0 0;
  }

  label {
    margin: 0;
  }

  .btn.-small {
    border-radius: 2rem;
    margin-left: 1rem;
    background-color: #eee;

    &:hover {
      @include bc-contrast;
      color: white;
    }
  }

  .form-group {
    @media (min-width: 993px) {
      margin-bottom: 0;
    }
  }

  .btn-submit {
    align-self: flex-end;
  }

  .form-check-input {
    height: auto;
    line-height: 1;
  }

  .form-check-label {
    font-size: small;
  }

  input[name="questionVariation"]:checked {

    & + .btn {
      @include bc-contrast;
      color: white;
    }
  }
}

.list-questions {

  .section {
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: 0) and (max-width: 576px) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @media (min-width: 993px) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  .question-item {
    // box-shadow: inset 0 2px 0 #eeeeee;
  }
}

#html {
  .question {

    &.-answered {

      .btn,
      label,
      .answer-item {
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-drag: none;
      }

      .btn {
        color: #aaa;
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #aaa;
      }
    }

    &.-right-answer {

      .question-id {
        @include bc-green;
        color: white;
      }

      input:checked {
        & ~ .desc {
          @include c-green;
        }

        & + .option {
          @include bc-green;
        }
      }

      .alert-right-answer {
        display: flex;
      }

      .alert-wrong-answer {
      }

      .alert-answer {
      }
    }

    &.-wrong-answer {

      .question-id {
        @include bc-red;
        color: white;
      }

      input:checked {
        & ~ .desc {
          @include c-red;
        }

        & + .option {
          @include bc-red;
        }
      }

      .alert-wrong-answer {
        display: flex;
      }

      .alert-answer {
        display: flex;
      }
    }
  }
}

.question-item {
  padding: 0;
}

.question {
  box-shadow: 0 0 0 2px #eee;
  border-radius: 5px;
  overflow: hidden;

  &.-check {
    .answer-item label {
      align-itemPixes: center;
    }
  }

  .question-breadcrumb {
    line-height: 1;
    border-bottom: 2px solid #eee;

    @media (min-width: 768px) {
      display: flex;
    }

    &.-title {
      background-color: #eee;
      box-shadow: none;
      // border-radius: 5px;
      overflow: hidden;
      border: 0 none;
    }

    .question-id {
      background-color: #eeeeee;
      @include c-main;
      box-shadow: none !important;
      font-weight: bold;
    }

    li {
      @media (min-width: 0) and (max-width: 576px) {
        padding: 0.5rem;
      }

      @media (min-width: 768px) {
        padding: 0.5rem 1rem;
      }

      &.li-icon {
        padding-left: 0;
        padding-right: 0;

        .fa {
          font-size: 1.15rem;
          line-height: 0.75;
          text-shadow: 0 0 0;
        }
      }
    }
  }

  .question-body {
    @media (min-width: 0) and (max-width: 576px) {
      padding: 1rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      padding: 1.5rem;
    }

    @media (min-width: 993px) {
      padding: 2rem;
    }

    .title {
      font-size: inherit;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 2rem;
      font-weight: unset;
    }

    .subtitle {
      font-size: inherit;
    }

    .desc {
      font-weight: normal;
    }
  }

  .question-answers {
  }

  .answer-item {
    // font-size: 1rem;
    @include anima;

    &:hover {
      text-shadow: 0 0 0;
    }

    & + .answer-item {
      margin-top: 1.5rem;
    }

    label {
      display: flex;
      margin: 0;
      cursor: pointer;
    }

    input:checked {

      & ~ .desc {
        font-weight: 600;
        text-shadow: 0 0 0;
        color: #232323;
      }

      & + .option {
        color: white;
        @include bc-contrast;
        font-weight: 300;
      }
    }

    .option {
      display: inline-block;
      min-width: 2rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      background-color: #eee;
      margin-right: 1.5rem;
      border-radius: 2rem;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
    }

    p {
      margin: 0;
    }
  }

  .question-actions {
    @media (min-width: 768px) {
      display: flex;
      align-itemPixes: center;
    }

    font-weight: bold;
    line-height: 1;
    padding: 0 2rem 2rem 2rem;

    li {
      display: flex;
      align-itemPixes: center;

      @media (min-width: 0) and (max-width: 576px) {
        & + li {
          margin-top: 1rem;
        }
      }

      @media (min-width: 768px) {
        & + li {
          margin-left: 1.5rem;
        }
      }

      .fa {
        font-size: 1.5rem;
        line-height: 0.8;
        margin-right: 0.5rem;
      }
    }

    .btn {
    }

    .alert-right-answer {
      @include c-green;
      display: none;
    }

    .alert-wrong-answer {
      @include c-red;
      display: none;
    }

    .alert-answer {
      // @include c-main;
      display: none;

      strong {
        font-size: 1rem;
        margin-left: 2px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }

  .question-footer {
    padding: 1rem 2rem;
    @include bc-light;

    .nav {
      justify-content: space-between;
      align-itemPixes: center;

      .nav-link {
        border-radius: 2rem;
        background: transparent;
        font-weight: bold;

        &:hover {
          box-shadow: inset 0 0 0 2px $c-alt;
          color: $c-alt;
        }

        &.active,
        &:active {
          color: white;
          @include bc-alt;
        }
      }
    }

    .tab-pane {
      padding: 2rem 0;

      .tab-pane-header {
        margin-bottom: 2rem;

        .title {
          font-size: 1rem;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 0.5rem;
        }

        .subtitle {
          font-size: inherit;
          line-height: 1;
        }
      }

      .item-avaliacao {

        & + .item-avaliacao {
          margin-top: 2rem;
        }
      }

      .avaliacao {
        display: flex;
        flex-flow: row nowrap;

        .avaliacao-header {
          padding: 0;
          margin-right: 1rem;
          text-align: center;
          flex: 0 0 16.6666%;
          max-width: 16.6666%;

          .title {
            font-size: inherit;
            margin-bottom: 0.5rem;
            margin-top: 1rem;
          }
        }

        .avaliacao-article {
          @include box-card-account;
          display: block;
        }

        .datetime {
          color: $c-mutted;
          font-size: small;
          line-height: 1.2;
        }
      }
    }
  }
}

.adquirir-plano {
  text-align: center;
  @include c-main;

  .container {
    position: relative;
  }

  img {
    opacity: 0.166678;
  }

  .title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }
}

//
// _____________________________________________________________________
// Home
.page.-home {

  .nav-item.-home {
    @include c-main;

    a::before {
      filter: grayscale(0) !important;
    }
  }
}

.home-features {
  padding-bottom: 0;
  @include bc-light;

  .row {
    @media (max-width: 575.98px) {
      justify-content: center;
    }

    @media (min-width: 576px) {
      justify-content: space-between;
    }
  }

  .home-feature {
    line-height: 1.2;

    @media (max-width: 575.98px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    figure {
      display: flex;
      flex-flow: wrap row;
      align-itemPixes: center;

      img {
        margin-right: 1rem;
      }

      strong {
        display: block;
        font-size: 1rem;
        font-weight: bold;
        color: #333;
      }
    }
  }
}

//
// _____________________________________________________________________
// Institucional

.page.-institucional {
  background-image: url(../img/svg-institucional-bg-branco.svg);
  background-position: top -2px left;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (min-width: 993px) {
    &::after {
      display: block;
      content: url(../img/svg-institucional-bg-azul.svg);
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      z-index: 2;
      opacity: 0.05;
    }
  }

  .main-header,
  .page-title,
  .header-bottom-bar {
    background-color: transparent;
  }

  .main-content {
    background-image: none;
  }

  .main-footer {
    box-shadow: none;
    z-index: 3;
    position: relative;
    // margin-top: 4rem;
  }

  .nav-item.-institucional {
    @include c-main;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .page-title {
    padding: 0;
    margin: 0;

    .breadcrumb {
      margin: 0;
      padding: 0.5rem 0;
      font-size: 12px;

      span {
        @include c-main;
      }
    }
  }
}

//
// _____________________________________________________________________
// Provas

.listagem-provas {

  .prova-item {

    &:nth-child(even) {

      .prova {
        @include bc-light;
      }
    }
  }

  .prova {
    display: flex;
    align-itemPixes: center;
    justify-content: space-between;
    padding: 2rem;

    &:hover {

      .fa,
      .title {
        color: $c-provas;
      }
    }

    .body {
    }

    .title-prova {
      font-size: small;
      margin-bottom: 0;
      line-height: 1;
    }

    .title {
      font-weight: bold;
      color: #232323;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .datetime {
      line-height: 1;
    }

    .fa {
      font-size: 2rem;
      @include c-main;
    }

    .actions {
      display: flex;
      align-itemPixes: center;

      .dropdown {
        margin-left: 1rem;

        .dropdown-toggle::after {
          display: none;
        }
      }
    }
  }
}

//
// _____________________________________________________________________
// Prova

.prova {

  ol {
    counter-reset: section;

    li {
      position: relative;
    }

    li::before {
      counter-increment: section;
      content: counters(section, ".") " ";
      display: inline-block;
      margin-right: 0.25rem;
    }
  }

  .title-questoes {
    @include bc-light;
    box-shadow: 0 0 0 2px #f8f8fa;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
    padding: 1rem;
  }

  .listagem-questoes-prova {
    box-shadow: 0 0 0 2px #f8f8fa;
    border-radius: 5px;
    overflow: hidden;

    & > li + li {
      box-shadow: 0 -2px 0 0 #f8f8fa;
    }
  }

  .questao-prova {

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem;
      line-height: 1.2;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .card-header {
    background-color: transparent;
    padding: 1rem 2.25rem;
    line-height: 1;
    cursor: pointer;
  }

  .card-title {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;

    strong {
      @include c-contrast;
    }
  }

  .card-body {
    padding: 0 2rem 1rem 2rem;

    .questao-prova-materia {
      padding-right: 6rem;
      line-height: 1.2;

      & + .questao-prova-materia {
        margin-top: 1rem;
      }

      .qtd {
        @include c-contrast;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

//
// _____________________________________________________________________
// Area Restrita

.account {

  .navbar-nav {
    align-itemPixes: center;
  }

  .main-nav-box-card {
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #eee;
  }

  hr.hr-account {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    background-color: #eee;
  }

  .title-message {
    font-weight: bold;
    font-size: 1rem;
  }
}

.box-card.-account {
  @include box-card-account;
  height: 100%;

  @media (min-width: 0) and (max-width: 992px) {
    padding: 2rem 1rem;
  }

  @media (min-width: 993px) {
    padding: 3rem 2rem;
  }

  .navbar {
    max-width: 100%;
  }

  .navbar-brand {
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: bold;
    color: #232323;
    padding: 0;
    margin: 0;

    @media (min-width: 577px) {
      margin: 0 2rem;
    }
  }

  .nav-item + .nav-item {
    @media (min-width: 0) and (max-width: 576px) {
      margin-top: 0.5rem;
    }

    @media (min-width: 577px) {
      margin-left: 1rem;
    }
  }

  .nav-link {
    line-height: 2rem;
    padding: 0 1rem;
    border-radius: 2rem;
    background-color: white;
    font-weight: bold;
    // box-shadow: 0 2px 0 0 rgba(0, 0, 0, .03), 0 4px 0 0 rgba(0, 0, 0, .02);
    box-shadow: inset 0 0 0 2px #eee;

    &:hover,
    &:active,
    &.active {
      @include bc-alt;
      box-shadow: inset 0 0 0 2px $c-alt;
      color: white;
    }
  }
}

//
// _____________________________________________________________________
// Dashboard

.dashboard {
}

.dashboard-features {

  @media (min-width: 0) and (max-width: 576px) {
    li {

      & + li {
        margin-top: 1rem;
      }
    }
  }

  .dashboard-feature {
    @include box-card-account;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid transparent;

    &.-questoes {
      @include c-contrast;
      border-bottom-color: inherit;
    }

    &.-respostas-corretas {
      @include c-green;
      border-bottom-color: inherit;
    }

    &.-respostas-erradas {
      @include c-red;
      border-bottom-color: inherit;
    }

    &.-list-item {
      color: inherit;
      border-bottom-color: inherit;

      .title {
        margin-bottom: 1rem;
      }
    }

    .qtd {
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }

    .title {
      color: $c-mutted;
      font-size: 1.25rem;
      font-weight: 300;
      margin: 0;
    }

    .info {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1rem;
      color: $c-mutted;
      font-size: 1.25rem;
    }
  }
}

.dashboard-estatisticas {

  .box-card.-account {
  }
}

.dashboard-grafico {
}

.grafico-wrapper {
  position: relative;
  display: flex;

  &::before {
    content: "";
    padding-top: 100%;
    display: block;
    float: left;
  }

  .grafico {
    height: 100%;
  }
}

.graficos {
}

//
// _____________________________________________________________________
// Paginas com coluna ao centro

// .page.-centered-layout {

//   // .main-header:not(.is-fixed),
//   // .page-title,
//   .main-content {
//     max-width: 1440px;
//     margin-left: auto;
//     margin-right: auto;
//   }

//   .page-title,
//   .main-header {
//     // background-color: white;
//   }

//   .main-content {
//     background-color: white;
//     // border-top: 1px solid #eee;
//   }
// }

//
// _____________________________________________________________________
// pág.: Carrinho

.carrinho-frete {
}

.section-hero.-carrinho {
  display: flex;
  justify-content: space-between;
  align-itemPixes: center;

  .btns {

    .btn + .btn {
      margin-left: 1rem;
    }
  }
}

//
// _____________________________________________________________________
// pág.: Pagamento

.listagem.-itens-pedido {

  td {
    text-align: center;

    &:first-child {
      width: 60%;
      text-align: left;
    }

    &:last-child {
      text-align: inherit;
      white-space: nowrap;
    }
  }
}

.pagamento-main {

  .box-card {

    & + .box-card {
      margin-top: 2rem;
    }
  }
}

.pagamento-aside {

  .box-card {

    & + .box-card {
      margin-top: 2rem;
    }
  }
}

.form {

  &.-gateway {
  }

  &.form-pagamento-cartao {

    label {
      display: flex;
      flex-flow: column nowrap;
      margin: 0;

      span {
        display: inline-block;
        line-height: 1;
      }

      span + .form-control {
        margin-top: 0.5rem;
      }
    }
  }
}

.info-tooltip {
  color: $c-mutted;
  font-size: 1rem;
  vertical-align: middle;
  line-height: 0.85 !important;

  .fa {
    line-height: 0.85;
  }
}

.box-card.-resumo-pedido {

  .account-dl {
  }
}

//
// _____________________________________________________________________
// Gateway - Páginas de pagamento

.page.-gateway {
  background: $c-light;

  .main-content {
    background: none;
  }

  .main-footer {
    background-color: white;
    box-shadow: inset 0 2px 0 #eee;
  }

  .header-top-bar,
  .btn-wishlist,
  .btn-cart,
  .header-bottom-bar {
    display: none !important;
  }

  .btn-planos,
  .float-button-whatsapp,
  .institucional-bar {
    display: none !important;
  }

  .header-middle-bar {
    padding: 1rem 0;
  }

  .main-content .section {
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: 0) and (max-width: 576px) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @media (min-width: 993px) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  .box-card {
    border-radius: 5px;
    // height: 100%;
    padding: 2rem;
    overflow: hidden;

    &.-carrinho {
      padding: 0;

      .row {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }
    }

    .box {
      padding: 2rem;

      &.-total {
        // @include bc-alt;
        background: #ededee;
        color: inherit;
        text-align: right;

        .title {
          color: inherit;
        }
      }

      &.-frete {
        text-align: right;
        align-itemPixes: flex-end;
        justify-content: center;
        display: flex;
        flex-flow: column nowrap;

        .frete-title {
          font-size: 1rem;
          line-height: 1;
          font-weight: bold;
          margin: 0;
          text-transform: uppercase;
        }

        .frete-type {
          font-size: 1rem;
          line-height: 1;

          strong {
            font-size: 225%;
            display: block;
            font-weight: bold;
            text-transform: uppercase;
            line-height: inherit;
          }
        }

        .datetime {
          color: $c-mutted;
          font-size: small;
          line-height: 1;
        }
      }
    }

    .title {
      font-size: 1rem;
      text-transform: uppercase;
      color: #232323;
      line-height: 1;
      margin-bottom: 1.5rem;
      @include montserrat;
    }
  }

  .price {
    font-size: 1rem;
    line-height: 1.1;

    &.-subtotal {
      // color: $c-mutted;
      opacity: 0.8;
    }

    &.-frete {
    }

    &.-desconto {
    }

    &.-total {
      font-size: 2rem;
      color: #232323;
    }
  }

  .section-hero {
    position: relative;
    text-align: left;
  }

  .form {
    .form-control {
      padding: 0 1.15rem;
      line-height: 2.5rem;
      height: 2.5rem;
    }

    textarea.form-control {
      height: auto;
      line-height: 1.61803;
    }

    .form-group .form-group [class*="col-"] {
      padding: 0;
    }
  }

  .box-card {

    &.-total {
      text-align: right;
    }

    &.-frete-desconto {
      flex-flow: row wrap;
      padding: 0;

      .col-md-6 {
        padding: 0;
      }
    }
  }
}

$account-pages: "dashboard",
"editar",
"alterar-senha",
"boleto-gerar",
"assinaturas",
"meu-desempenho",
"minhas-estatisticas",
"minhas-questoes",
"meus-simulados",
"meus-cadernos",
"meus-cursos",
"meus-conteudos",
"pagamentos",
"editar",
"alterar-senha",
"boleto-gerar",
"assinaturas";

@each $accpage in $account-pages {
  .page.-account.-#{$accpage} {

    .nav-item.-#{$accpage} {
      @include c-alt;

      .nav-link {
        border-bottom-color: inherit;
      }
    }
  }
}

// Menu Area restrita - Menu Lateral
.account-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: auto;
  background-color: white;
  z-index: 987658;
  @include sombra-sm;
  width: 100%;
  max-width: 320px;
  padding-bottom: 4rem;
  overflow-y: auto;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateX(-105%);
  transition: all .5s cubic-bezier(.160, .560, .170, .999);

  &.-show {
    transform: translateX(0);
    @include sombra-lg;
  }

  .account-menu-header {

    .js-btn-menu-account {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      height: auto;
      line-height: 1;
      font-size: 1.25rem;
      color: #999;
      padding: 0.5rem 0.75rem;
    }
  }

  .account-menu-content {
  }

  .account-menu-nav {
  }

  .account-navigation {

    .nav-item {

      & + .nav-item {
        box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
      }

      @media (min-width: 993px) {
        padding-left: 1rem;
      }

      &:hover,
      &:active,
      &.active {
        @include bc-alt;
        color: $c-light;
      }

      .nav-link {
        display: flex;
        align-itemPixes: center;
        font-weight: bold;
      }

      .fa {
        font-size: 1.25rem;
        margin-right: 1rem;
        line-height: 1;
      }
    }
  }

  .user {
    @media (min-width: 0) and (max-width: 576px) {
      padding: 1.5rem;
    }

    @media (min-width: 577px) {
      padding: 2rem;
    }
  }
}

.user {
  .user-photo {
    position: relative;
    max-width: 128px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    background-image: url(../img/svg-user-photo.svg);

    &::before {
      display: block;
      padding-top: 100%;
      content: " ";
    }

    img {
      opacity: 0;
    }
  }

  .user-name {
    font-size: 1.25rem;
    color: #232323;
    line-height: 1;
    margin-top: 1rem;
    font-weight: bold;
    text-align: center;
  }
}

.account-dl {

  dt {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    color: $c-mutted;
    font-weight: normal;
  }

  dd {
    font-weight: bold;
  }

  dd + dt {
    margin-top: 1rem;
  }
}

.user-card {

  .account-dl {
    margin-top: 2rem;
  }
}

.form.-account {
  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-control {
    box-shadow: 0 2px 0px 0px #d9dde2;
    border-radius: 0;
    padding: 0;

    &:hover {
      box-shadow: 0 2px 0px 0px #99a;
    }

    &:focus {
      box-shadow: 0 2px 0px 0px $c-alt;
    }
  }

  .caption {
    font-size: 1rem;
  }
}

.form-actions {
  text-align: right;
  margin-top: 1rem;

  .btn + .btn {
    margin-left: 2rem;
  }
}

.account-aside {

  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

// Assinaturas

.listagem-assinaturas {

  .assinatura {
    padding: 0;

    & + .assinatura {
      margin-top: 2rem;
    }

    &.-current {

      .assinatura-aside {
        @include bc-alt;
        color: $c-light;
      }
    }

    .account-dl dt {
      font-weight: 600;
      color: rgba(255, 255, 255, 0.4);
    }

    .assinatura-content {
      padding: 2rem;

      @media (min-width: 577px) {
        padding: 3rem;
        display: flex;
        align-itemPixes: center;
        justify-content: space-between;
      }

      .title {
        font-size: 1rem;
        line-height: 1;

        strong {
          font-size: 300%;
          display: block;
          text-transform: uppercase;
        }

        .badge {
          vertical-align: top;
        }
      }

      .price {
        font-size: 1rem;
        line-height: 1;

        span {
          font-size: 300%;
          font-weight: bold;
        }
      }
    }

    .assinatura-aside {
      padding: 2rem;
      background: $c-mutted;
      color: $c-light;

      .title {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
}

// Estatisticas

.listagem {

  &.-estatisticas {
    text-align: center;
  }

  td {
    vertical-align: middle;
    padding: 0.5rem;

    &:first-child {
      width: 40%;
      text-align: left;
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  thead {

    td {
      font-weight: bold;
      font-size: 12px;
    }
  }

  tbody {

    td {
      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        text-align: right;
      }

      .btn {
        line-height: 1;
        height: auto;
        padding: 0.5rem 0.75rem;
      }
    }
  }
}

// Meus Simulados

.listagem.-simulados {

  .simulado-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-itemPixes: center;
    padding: 2rem 1rem;

    @media (min-width: 0) and (max-width: 576px) {
      align-itemPixes: flex-start;
    }

    &:nth-of-type(odd) {
      border-radius: 5px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, .05);
    }

    .si-content {
      display: flex;
      align-itemPixes: center;

      @media (min-width: 0) and (max-width: 576px) {
        flex-flow: column nowrap;
        align-itemPixes: flex-start;
      }

      &.-left {
        flex-flow: column nowrap;
        align-itemPixes: flex-start;

        .badge {
          border-radius: 5px;
          padding: .25rem 0.5rem;
          color: white;
          background: $c-mutted;
        }
      }

      &.-right {
        font-weight: 600;

        .col {
          white-space: nowrap;

          @media (min-width: 0) and (max-width: 576px) {
            max-width: 100%;
            flex: 0 0 100%;
          }

          &.-spacing {
            padding: 0;
            background-color: $c-mutted;
            height: 1px;
            max-height: 1px;
            margin: 0.5rem 0;

            @media (min-width: 577px) {
              width: 1px;
              height: 1rem;
              max-height: none;
              margin: auto 0.5rem;
            }
          }

          &.-status {
            color: $c-mutted;

            &.-false {
              color: $c-red;
            }

            &.-true {
              color: $c-green;
            }
          }
        }

        & > .btn {
          @media (max-width: 576px) {
            border-radius: 2rem;
            box-shadow: inset 0 0 0 2px $c-alt;
          }

          &:hover {
            border-radius: 2rem;
            box-shadow: inset 0 0 0 2px $c-alt;
          }

          &:focus,
          &:active,
          &.active {
            border-radius: 2rem;
            @include bc-alt;
            color: white;
          }
        }
      }
    }

    .title {
      font-size: 1rem;
      margin: .5rem 0;
    }

    .datetime {
      color: $c-mutted;
      font-size: small;
      line-height: 1;
    }

    .btn-editar,
    .btn-tronar-privado,
    .btn-remove {
      padding: 0;
      height: auto;
      line-height: 0.8;
      background: transparent;
      @include anima;
      cursor: pointer;

      &:hover {
        transform: translateY(-2px);
      }

      .fa {
        line-height: inherit;
        font-size: 1.5rem;
      }
    }

    .btn-remove {
      color: $c-red;
    }

    .btn-editar {
      color: $c-alt;
    }

    .btn-tronar-privado {
      color: $c-alt;
    }
  }
}

// Meus Cursos
.meus-cursos {
}

.listagem-cursos-account {

  & > .col {
    padding: 1rem;
  }

  .cursos-account {
  }

  .curso-account {
    @include box-card-account;
    padding: 0;
    height: 100%;
    justify-content: space-between;

    .embed-responsive {

      .curso-img {
      }
    }

    .desc {
      padding: 2rem 1rem;

      .subtitle {
        font-size: 12px;
        color: $c-mutted;
        text-transform: uppercase;
        line-height: 1.61803;
        font-weight: 600;
        display: block;
        margin-bottom: 0.25rem;
      }

      .title {
        font-size: 1.25rem;
        font-weight: bold;
      }
    }

    .curso-footer {
      padding: 1rem;
      border-top: 1px solid #eee;
      color: $c-mutted;
      font-size: 13px;

      p {
        line-height: 1;
      }
    }
  }
}

.listagem.-pagamentos {
  td:first-child {
    width: auto;
  }

  td:last-child {
    text-align: right;
  }
}

.carrinho-breadcrumb {
  padding: 2rem 0 0 0;
  margin: 0;

  .item {
    display: flex;
    flex-wrap: wrap;
    align-itemPixes: center;
    justify-content: center;
    color: $c-mutted;

    &.-active {
      @include c-green;

      .fa,
      .title {
        color: inherit;
      }
    }

    &.-current {
      .fa {
        color: white;
      }

      .icon-holder {
        background: $c-mutted;
      }
    }

    .icon-holder {
      background-color: white;
      box-shadow: 0 8px 17px 2px rgba(0, 0, 0, .025), 0 3px 14px 2px rgba(0, 0, 0, .025), 0 5px 5px -3px rgba(248, 186, 186, .05);
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      border-radius: 3rem;
      text-align: center;
      display: block;
      float: left;
      margin-right: 0.5rem;
    }

    .fa {
      line-height: inherit;
      font-size: 1.25rem;
    }

    .title {
      @include montserrat;
      font-size: inherit;
      font-weight: bold;
      line-height: 1.1;
      color: inherit;
    }
  }
}

.carrinho-vazio {
  text-align: center;

  .img {
    opacity: 0.584687;

    @media (min-width: 0) and (max-width: 576px) {
      width: 200px;
    }

    @media (min-width: 577px) and (max-width: 767px) {
      width: 256px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      width: 412px;
    }

    @media (min-width: 993px) {
      width: 412px;
    }
  }

  .title {
    @include montserrat;

    @media (min-width: 0) and (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media (min-width: 993px) {
      font-size: 2rem;
    }
  }
}

.table-listagem {
  text-align: center;

  td {
    vertical-align: middle;
    white-space: nowrap;
  }

  tr {
  }

  thead {
    td {
      font-weight: bold;
      padding: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
        text-align: left;
      }
    }
  }

  tbody {
    td {
      padding: 2rem;
    }

    td {
      &:first-child {
        border-radius: 5px 0 0 5px;
        text-align: left;
        font-weight: 600;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
        text-align: right;
      }
    }
  }

  &.-carrinho {

    tbody {

      td {
        &:first-child {
          width: 60%;
        }

        &:last-child {
          width: 10%;
        }
      }

      tr {
      }
    }

    .input-number {
      box-shadow: 0 0 0 2px #d9dde2;
      padding: 0;
      display: inline-block;
      text-align: center;
      width: 3rem;
      background: transparent;
      height: auto;
      line-height: 1.5rem;

      &:hover {
        box-shadow: 0 0 0 2px #99a;
      }

      &:focus {
        box-shadow: 0 0 0 2px $c-alt;
      }
    }
  }
}

//
// _____________________________________________________________________
// Cursos Online

.page.-cursos-online {

  .nav-item.-cursos-online {
    @include c-cursos;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .breadcrumb span {
    @include c-cursos;
  }
}

//
// _____________________________________________________________________
// Apostilas

.page.-apostilas {

  .nav-item.-apostilas {
    @include c-apostilas;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .breadcrumb span {
    @include c-apostilas;
  }
}

//
// _____________________________________________________________________
// Livros

.page.-livros {

  .nav-item.-livros {
    @include c-livros;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .breadcrumb span {
    @include c-livros;
  }
}

//
// _____________________________________________________________________
// Questões de Concurso

.page.-questoes-de-concursos {

  .nav-item.-questoes-de-concursos {
    @include c-questoes;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .breadcrumb span {
    @include c-questoes;
  }
}

//
// _____________________________________________________________________
// Provas

.page.-provas {

  .nav-item.-provas {
    @include c-provas;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .breadcrumb span {
    @include c-provas;
  }
}

//
// _____________________________________________________________________
// Notícias

.page.-noticias {

  .nav-item.-noticias {
    @include c-noticias;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .breadcrumb span {
    @include c-noticias;
  }
}

//
// _____________________________________________________________________
// Erratas e Redificação

.page.-erratas-e-redificacao {

  .nav-item.-erratas-e-redificacao {
    @include c-erratas;

    .nav-link {
      border-top-color: inherit;
    }
  }

  .breadcrumb span {
    @include c-erratas;
  }
}

//
// _____________________________________________________________________
// Páginas logged in

.btn-loggedin {
  display: none;
}

.main-header.-logged-in {

  .btn-login {
    display: none;
  }

  .btn-loggedin {
    display: flex;
    align-itemPixes: center;
    @include c-main;

    img {
      margin-right: .5rem;
    }
  }
}

//
// _____________________________________________________________________
// Area Restrita

.page.-account {
  @include bc-light;

  .main-content {
    background: transparent;
    @include clearfix;
  }

  .nav-item.-erratas-e-redificacao {
    @include c-alt;

    .nav-link {
      border-bottom-color: inherit;
    }
  }

  .main-navigation .nav-item.-sair {

    .nav-link {
      height: auto;
      line-height: 2rem;
      border: 0;
      padding: 0 1rem;
      border-radius: 4px;
      color: $c-contrast;
      background-color: transparent;
      -webkit-box-shadow: inset 0 0 0 2px $c-contrast;
      box-shadow: inset 0 0 0 2px $c-contrast;

      &:hover {
        color: white;
        background-color: $c-contrast;
      }
    }
  }

  .breadcrumb span {
    @include c-main;
  }

  .page-title {
    padding-top: 2rem;
    background: transparent;

    .title {
      // padding-top: 2rem;
    }

    .subtitle {
      font-size: 1.15rem;
      font-weight: 200;
    }

    .container:last-child::after {
      @include bc-contrast;
    }
  }

  .section {
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: 0) and (max-width: 576px) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    @media (min-width: 577px) and (max-width: 992px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @media (min-width: 993px) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  .btn-planos,
  .float-button-whatsapp,
  .institucional-bar {
    display: none;
  }

  .btn-menu-account {
    color: $c-light;
    background: transparent;
    display: flex;
    align-itemPixes: center;
    margin-left: 1rem;

    &:hover {
      @include bc-light;
      color: $c-main;
    }

    .fa {
      font-size: 1.75rem;
      margin-right: 0.5rem;
    }
  }

  .header-bottom-bar {
    box-shadow: none;
  }

  .main-navigation {

    .nav-link {
      font-size: 13px;
      padding: 0;

      .fa {
        display: none;
      }
    }
  }
}

//
// _____________________________________________________________________
// AJUSTES

.menu-ecommerce strong {
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product .variations .variation {
  position: relative;
  margin-bottom: 2rem;
  padding-left: 0px;
  width: 100%;
}

.product .variations .variation label {
  padding-left: 34px;
}

.social-list-mobile {
  display: inline-block;
  width: 160px;
  text-align: center;
}

.social-list-mobile li {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin: 0 6px;
}

.social-list-mobile li a {
  font-size: 18px;
  color: #172983;
}

@media only screen and (max-width: 480px) {
  .menu-ecommerce strong {
    max-width: 70px;
  }
}

// Professores

.listagem-professores {
}

.professor-item {
  padding: 1.5rem;
}

.professor {
  @include box-card;
  cursor: pointer;

  &:hover {

    .embed-responsive {
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        font-family: 'FontAwesome';
        font-size: 3rem;
        text-rendering: auto;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f002";
        z-index: 10;
        transform: translateX(-50%) translateY(-50%);
      }

      .professor-img {
        opacity: 0.5;
      }
    }
  }

  .embed-responsive {
    background-color: $c-main;

    .professor-img {
      width: 200px;
      height: 200px;

    }
  }

  .professor-caption {
    margin-bottom: 1rem;
  }

  .professor-title {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .professor-subtitle {
  }

  .professor-desc {
  }
}

.rounded-circle {
  width: 180px;
  height: 179px;
  left: 8px;
}