@charset "UTF-8";
/*!
 * ILION
 *
 * @year 2019
 * @copyright Ilion
 *
 */
/*
* reset.css
* by Paulo Melo
*/
/**
* At-rules - Set the encoding of the style sheet to Unicode UTF-8
* Mozilla Developer Network [https://developer.mozilla.org/en-US/docs/Web/CSS/@charset]
*
* @charset "UTF-8";
*/
/**
* Basic UI - Apply a natural box layout model to all elements. [Firefox, WebKit, Opera and IE8+]
* Paul Irish [http://www.paulirish.com/2012/box-sizing-border-box-ftw/]
*/
html {
  box-sizing: border-box;
}

html * {
  box-sizing: inherit;
}

/**
* Basic Box Model - Correct 'block' display not defined in [IE 8+]
*/
article,
aside,
datagrid,
datalist,
details,
dialog,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

abbr,
eventsource,
mark,
meter,
time,
output,
bb {
  display: inline;
}

/* Basic Box Model - Remove default Margin. */
body,
blockquote,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/* Basic Box Model - Remove default Margin and Padding. */
button,
code,
dd,
dl,
dt,
fieldset,
hr,
input,
legend,
menu,
ol,
pre,
select,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

/* Remove default Border. */
a img,
abbr,
button,
fieldset,
hr,
iframe,
input,
select,
table,
textarea {
  border: 0 none;
}

/* Alignments */
label,
button,
input,
select,
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

@media (min-width: 0) and (max-width: 576px) {
  :root {
    font: normal normal normal 13px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  :root {
    font: normal normal normal 14px/1.4 -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  :root {
    font: normal normal normal 15px/1.3 -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }
}

@media (min-width: 993px) {
  :root {
    font: normal normal normal 100%/1.2 -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  }
}

@keyframes animeDropdown {
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
}

@keyframes animeStickyHeader {
  from {
    transform: translateY(-4rem) translateZ(-500px);
    opacity: 0;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.07), 0 9px 46px 8px rgba(0, 0, 0, 0.06), 0 11px 15px -7px rgba(0, 0, 0, 0.1);
  }
}

/*
  base.css */
:root,
body {
  overflow-x: hidden;
}

@media only screen and (max-device-width: 1024px) {
  :root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
}

button,
input,
optgroup,
option,
select,
textarea {
  font: normal normal normal 100%/normal -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  text-rendering: optimizespeed;
}

address,
b,
caption,
cite,
code,
dfn,
em,
h1,
h2,
h3,
h4,
h5,
h6,
i,
optgroup,
option,
strong,
th,
var {
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizespeed;
}

table {
  font-size: inherit;
  width: 100%;
}

address,
p,
dl,
blockquote,
pre,
td,
th,
label,
ol,
ul,
textarea {
  line-height: 1.61803;
  text-rendering: optimizespeed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 2rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

p {
  margin-bottom: 1rem;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

caption,
th {
  text-align: left;
}

small {
  font-size: 80%;
}

ul,
menu,
dir,
ol {
  list-style: none;
}

html:focus,
a:hover,
a:active {
  outline: 0;
}

:active,
:focus {
  outline: 0 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
svg {
  max-width: 100%;
  height: auto;
}

object,
embed {
  position: relative;
  max-width: 100%;
  height: 100%;
}

embed,
img,
object,
svg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}

dl,
dt,
dd {
  vertical-align: top;
}

.lt-ie8 img {
  -ms-interpolation-mode: bicubic;
}

abbr[title] {
  border-bottom: 1px dotted;
}

[hidden] {
  display: none;
}

[readonly],
[disabled] {
  cursor: default;
  opacity: .75;
}

[disabled],
[readonly],
[unselectable] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

blockquote:last-child,
pre:last-child,
table:last-child {
  margin-bottom: 0;
}

code,
pre,
samp {
  white-space: pre-wrap;
}

code {
  line-height: 1;
}

kbd {
  padding: 0 .25rem;
  border: solid 1px;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: dotted 1px;
}

hr {
  display: block;
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: #ddd;
}

@media screen {
  [hidden~=screen] {
    display: inherit;
  }
  [hidden~=screen]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}

/*
    typography.css */
body {
  color: rgba(68, 68, 68, 0.8);
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-device-width: 576px) {
  html body {
    color: black;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.55rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1.1rem;
  }
  h6 {
    font-size: 1.05rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  h1 {
    font-size: 2.1rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1.15rem;
  }
}

@media (min-width: 993px) {
  h1 {
    font-size: 2.75rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.15rem;
  }
  h6 {
    font-size: 1rem;
  }
}

h1,
h2 {
  font-weight: 200;
}

h3,
h4 {
  font-weight: 600;
}

h5,
h6 {
  font-weight: 700;
}

dfn,
em,
i {
  font-style: italic;
}

b,
label,
option,
strong {
  font-weight: 600;
}

.text-thin {
  font-weight: 200;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-black {
  font-weight: 800;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UN8rsOUuhpKKSTjw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lora';
  font-weight: 400;
  font-style: normal;
  src: local("Lora Regular"), local("Lora-Regular"), url(https://fonts.gstatic.com/s/lora/v12/0QIvMX1D_JOuMwr7I_FMl_E.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.-opensans,
._ff-opensans {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-weight: 400;
}

.-lora,
._ff-lora {
  font-family: 'Lora', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: normal;
  font-style: italic;
}

.-montserrat,
._ff-montserrat {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-style: normal;
  letter-spacing: -1px;
  word-spacing: .25rem;
  font-weight: 400;
}

#html .c-main {
  color: #162882 !important;
}

#html .bc-main {
  background-color: #162882 !important;
}

#html .btn-main {
  background-color: #162882;
  color: white;
}

#html .btn-main:hover {
  background-color: #1f38b6;
}

#html .btn-main.active, #html .btn-main:active {
  background-color: #122068;
}

#html .btn-main:focus {
  box-shadow: 0 0 0 0.2rem rgba(22, 40, 130, 0.25);
}

#html .btn-border-main {
  color: #162882;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #162882;
}

#html .btn-border-main:hover, #html .btn-border-main:active {
  background-color: #162882;
  color: white;
}

#html .c-primary {
  color: #162882 !important;
}

#html .bc-primary {
  background-color: #162882 !important;
}

#html .btn-primary {
  background-color: #162882;
  color: white;
}

#html .btn-primary:hover {
  background-color: #1f38b6;
}

#html .btn-primary.active, #html .btn-primary:active {
  background-color: #122068;
}

#html .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(22, 40, 130, 0.25);
}

#html .btn-border-primary {
  color: #162882;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #162882;
}

#html .btn-border-primary:hover, #html .btn-border-primary:active {
  background-color: #162882;
  color: white;
}

#html .c-contrast {
  color: #fd7a0f !important;
}

#html .bc-contrast {
  background-color: #fd7a0f !important;
}

#html .btn-contrast {
  background-color: #fd7a0f;
  color: white;
}

#html .btn-contrast:hover {
  background-color: #fe9c4c;
}

#html .btn-contrast.active, #html .btn-contrast:active {
  background-color: #eb6b02;
}

#html .btn-contrast:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 122, 15, 0.25);
}

#html .btn-border-contrast {
  color: #fd7a0f;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #fd7a0f;
}

#html .btn-border-contrast:hover, #html .btn-border-contrast:active {
  background-color: #fd7a0f;
  color: white;
}

#html .c-secondary {
  color: #fd7a0f !important;
}

#html .bc-secondary {
  background-color: #fd7a0f !important;
}

#html .btn-secondary {
  background-color: #fd7a0f;
  color: white;
}

#html .btn-secondary:hover {
  background-color: #fe9c4c;
}

#html .btn-secondary.active, #html .btn-secondary:active {
  background-color: #eb6b02;
}

#html .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 122, 15, 0.25);
}

#html .btn-border-secondary {
  color: #fd7a0f;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #fd7a0f;
}

#html .btn-border-secondary:hover, #html .btn-border-secondary:active {
  background-color: #fd7a0f;
  color: white;
}

#html .c-alt {
  color: #206cd7 !important;
}

#html .bc-alt {
  background-color: #206cd7 !important;
}

#html .btn-alt {
  background-color: #206cd7;
  color: white;
}

#html .btn-alt:hover {
  background-color: #4f8de5;
}

#html .btn-alt.active, #html .btn-alt:active {
  background-color: #1c5fbc;
}

#html .btn-alt:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 108, 215, 0.25);
}

#html .btn-border-alt {
  color: #206cd7;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #206cd7;
}

#html .btn-border-alt:hover, #html .btn-border-alt:active {
  background-color: #206cd7;
  color: white;
}

#html .c-green {
  color: #0db870 !important;
}

#html .bc-green {
  background-color: #0db870 !important;
}

#html .btn-green {
  background-color: #0db870;
  color: white;
}

#html .btn-green:hover {
  background-color: #14ee92;
}

#html .btn-green.active, #html .btn-green:active {
  background-color: #0b9b5f;
}

#html .btn-green:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 184, 112, 0.25);
}

#html .btn-border-green {
  color: #0db870;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #0db870;
}

#html .btn-border-green:hover, #html .btn-border-green:active {
  background-color: #0db870;
  color: white;
}

#html .c-grey {
  color: #e5e5e5 !important;
}

#html .bc-grey {
  background-color: #e5e5e5 !important;
}

#html .btn-grey {
  background-color: #e5e5e5;
  color: white;
}

#html .btn-grey:hover {
  background-color: white;
}

#html .btn-grey.active, #html .btn-grey:active {
  background-color: #d6d6d6;
}

#html .btn-grey:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.25);
}

#html .btn-border-grey {
  color: #e5e5e5;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #e5e5e5;
}

#html .btn-border-grey:hover, #html .btn-border-grey:active {
  background-color: #e5e5e5;
  color: white;
}

#html .c-red {
  color: #f12b2b !important;
}

#html .bc-red {
  background-color: #f12b2b !important;
}

#html .btn-red {
  background-color: #f12b2b;
  color: white;
}

#html .btn-red:hover {
  background-color: #f56464;
}

#html .btn-red.active, #html .btn-red:active {
  background-color: #ee1010;
}

#html .btn-red:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 43, 43, 0.25);
}

#html .btn-border-red {
  color: #f12b2b;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #f12b2b;
}

#html .btn-border-red:hover, #html .btn-border-red:active {
  background-color: #f12b2b;
  color: white;
}

#html .c-light {
  color: #f8f8fa !important;
}

#html .bc-light {
  background-color: #f8f8fa !important;
}

#html .btn-light {
  background-color: #f8f8fa;
  color: white;
}

#html .btn-light:hover {
  background-color: white;
}

#html .btn-light.active, #html .btn-light:active {
  background-color: #e6e6ed;
}

#html .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 250, 0.25);
}

#html .btn-border-light {
  color: #f8f8fa;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #f8f8fa;
}

#html .btn-border-light:hover, #html .btn-border-light:active {
  background-color: #f8f8fa;
  color: white;
}

#html .c-white {
  color: white !important;
}

#html .bc-white {
  background-color: white !important;
}

#html .btn-white {
  background-color: white;
  color: white;
}

#html .btn-white:hover {
  background-color: white;
}

#html .btn-white.active, #html .btn-white:active {
  background-color: #f0f0f0;
}

#html .btn-white:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

#html .btn-border-white {
  color: white;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px white;
}

#html .btn-border-white:hover, #html .btn-border-white:active {
  background-color: white;
  color: white;
}

#html .c-black {
  color: black !important;
}

#html .bc-black {
  background-color: black !important;
}

#html .btn-black {
  background-color: black;
  color: white;
}

#html .btn-black:hover {
  background-color: #1f1f1f;
}

#html .btn-black.active, #html .btn-black:active {
  background-color: black;
}

#html .btn-black:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

#html .btn-border-black {
  color: black;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px black;
}

#html .btn-border-black:hover, #html .btn-border-black:active {
  background-color: black;
  color: white;
}

#html .c-mutted {
  color: #aaa !important;
}

#html .bc-mutted {
  background-color: #aaa !important;
}

#html .btn-mutted {
  background-color: #aaa;
  color: white;
}

#html .btn-mutted:hover {
  background-color: #c9c9c9;
}

#html .btn-mutted.active, #html .btn-mutted:active {
  background-color: #9b9b9b;
}

#html .btn-mutted:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 170, 170, 0.25);
}

#html .btn-border-mutted {
  color: #aaa;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #aaa;
}

#html .btn-border-mutted:hover, #html .btn-border-mutted:active {
  background-color: #aaa;
  color: white;
}

#html .c-livros {
  color: #d667c8 !important;
}

#html .bc-livros {
  background-color: #d667c8 !important;
}

#html .btn-livros {
  background-color: #d667c8;
  color: white;
}

#html .btn-livros:hover {
  background-color: #e397d9;
}

#html .btn-livros.active, #html .btn-livros:active {
  background-color: #cf4fbf;
}

#html .btn-livros:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 103, 200, 0.25);
}

#html .btn-border-livros {
  color: #d667c8;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #d667c8;
}

#html .btn-border-livros:hover, #html .btn-border-livros:active {
  background-color: #d667c8;
  color: white;
}

#html .c-questoes {
  color: #ffc965 !important;
}

#html .bc-questoes {
  background-color: #ffc965 !important;
}

#html .btn-questoes {
  background-color: #ffc965;
  color: white;
}

#html .btn-questoes:hover {
  background-color: #ffdea2;
}

#html .btn-questoes.active, #html .btn-questoes:active {
  background-color: #ffbe46;
}

#html .btn-questoes:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 201, 101, 0.25);
}

#html .btn-border-questoes {
  color: #ffc965;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffc965;
}

#html .btn-border-questoes:hover, #html .btn-border-questoes:active {
  background-color: #ffc965;
  color: white;
}

#html .c-destaques {
  color: #ffda95 !important;
}

#html .bc-destaques {
  background-color: #ffda95 !important;
}

#html .btn-destaques {
  background-color: #ffda95;
  color: white;
}

#html .btn-destaques:hover {
  background-color: #ffefd2;
}

#html .btn-destaques.active, #html .btn-destaques:active {
  background-color: #ffcf76;
}

#html .btn-destaques:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 218, 149, 0.25);
}

#html .btn-border-destaques {
  color: #ffda95;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffda95;
}

#html .btn-border-destaques:hover, #html .btn-border-destaques:active {
  background-color: #ffda95;
  color: white;
}

#html .c-noticias {
  color: #bbbbbb !important;
}

#html .bc-noticias {
  background-color: #bbbbbb !important;
}

#html .btn-noticias {
  background-color: #bbbbbb;
  color: white;
}

#html .btn-noticias:hover {
  background-color: #dadada;
}

#html .btn-noticias.active, #html .btn-noticias:active {
  background-color: #acacac;
}

#html .btn-noticias:focus {
  box-shadow: 0 0 0 0.2rem rgba(187, 187, 187, 0.25);
}

#html .btn-border-noticias {
  color: #bbbbbb;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #bbbbbb;
}

#html .btn-border-noticias:hover, #html .btn-border-noticias:active {
  background-color: #bbbbbb;
  color: white;
}

#html .c-depoimentos {
  color: #c3c6e9 !important;
}

#html .bc-depoimentos {
  background-color: #c3c6e9 !important;
}

#html .btn-depoimentos {
  background-color: #c3c6e9;
  color: white;
}

#html .btn-depoimentos:hover {
  background-color: #f0f1f9;
}

#html .btn-depoimentos.active, #html .btn-depoimentos:active {
  background-color: #adb1e1;
}

#html .btn-depoimentos:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 198, 233, 0.25);
}

#html .btn-border-depoimentos {
  color: #c3c6e9;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #c3c6e9;
}

#html .btn-border-depoimentos:hover, #html .btn-border-depoimentos:active {
  background-color: #c3c6e9;
  color: white;
}

#html .c-cursos {
  color: #0fb970 !important;
}

#html .bc-cursos {
  background-color: #0fb970 !important;
}

#html .btn-cursos {
  background-color: #0fb970;
  color: white;
}

#html .btn-cursos:hover {
  background-color: #19ec92;
}

#html .btn-cursos.active, #html .btn-cursos:active {
  background-color: #0d9d5f;
}

#html .btn-cursos:focus {
  box-shadow: 0 0 0 0.2rem rgba(15, 185, 112, 0.25);
}

#html .btn-border-cursos {
  color: #0fb970;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #0fb970;
}

#html .btn-border-cursos:hover, #html .btn-border-cursos:active {
  background-color: #0fb970;
  color: white;
}

#html .c-erratas {
  color: #f86a60 !important;
}

#html .bc-erratas {
  background-color: #f86a60 !important;
}

#html .btn-erratas {
  background-color: #f86a60;
  color: white;
}

#html .btn-erratas:hover {
  background-color: #fba19b;
}

#html .btn-erratas.active, #html .btn-erratas:active {
  background-color: #f74f43;
}

#html .btn-erratas:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 106, 96, 0.25);
}

#html .btn-border-erratas {
  color: #f86a60;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #f86a60;
}

#html .btn-border-erratas:hover, #html .btn-border-erratas:active {
  background-color: #f86a60;
  color: white;
}

#html .c-apostilas {
  color: #fd8f36 !important;
}

#html .bc-apostilas {
  background-color: #fd8f36 !important;
}

#html .btn-apostilas {
  background-color: #fd8f36;
  color: white;
}

#html .btn-apostilas:hover {
  background-color: #feb173;
}

#html .btn-apostilas.active, #html .btn-apostilas:active {
  background-color: #fd7e18;
}

#html .btn-apostilas:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 143, 54, 0.25);
}

#html .btn-border-apostilas {
  color: #fd8f36;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #fd8f36;
}

#html .btn-border-apostilas:hover, #html .btn-border-apostilas:active {
  background-color: #fd8f36;
  color: white;
}

#html .c-provas {
  color: #80acff !important;
}

#html .bc-provas {
  background-color: #80acff !important;
}

#html .btn-provas {
  background-color: #80acff;
  color: white;
}

#html .btn-provas:hover {
  background-color: #bdd4ff;
}

#html .btn-provas.active, #html .btn-provas:active {
  background-color: #6198ff;
}

#html .btn-provas:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 172, 255, 0.25);
}

#html .btn-border-provas {
  color: #80acff;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #80acff;
}

#html .btn-border-provas:hover, #html .btn-border-provas:active {
  background-color: #80acff;
  color: white;
}

#html address {
  margin-bottom: 0;
}

#html input,
#html select,
#html textarea {
  font-size: 14px;
}

#html a.link {
  text-decoration: underline;
}

#html .c-facebook {
  color: #3b5998;
}

#html .c-twitter {
  color: #55acee;
}

#html .c-googleplus {
  color: #dd4c3b;
}

#html .c-instagram {
  color: #C13584;
}

#html .c-youTube {
  color: #ff0416;
}

#html .c-pinterest {
  color: #bd081c;
}

#html .bc-facebook {
  background-color: #4267b2;
}

#html .bc-instagram {
  background-image: radial-gradient(circle farthest-corner at 32% 106%, #ffe17d 0, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), linear-gradient(135deg, #234bd7 12%, #c33cbe 58%);
}

#html .bc-twitter {
  background-color: #03b8ec;
}

#html .bc-linkedin {
  background-color: #0284ba;
}

#html .bc-googleplus {
  background-color: #ee5352;
}

#html .bc-youtube,
#html .bc-youTube {
  background-color: #ff0416;
}

#html .bc-pinterest {
  background-color: #bd081c;
}

#html .fa-instagram:hover {
  color: #C13584;
}

#html .fa-facebook-square:hover,
#html .fa-facebook:hover {
  color: #4267b2;
}

#html .fa-twitter-square:hover,
#html .fa-twitter:hover {
  color: #03b8ec;
}

#html .fa-linkedin:hover {
  color: #0284ba;
}

#html .fa-googleplus:hover {
  color: #ee5352;
}

#html .fa-youtube:hover {
  color: #ff0416;
}

#html .fa-pinterest:hover {
  color: #bd081c;
}

html {
  /* Inputs */
  /* select */
  /*
 * Micro clearfix
 * Copyright © Nicolas Gallagher
 * Source: [http://nicolasgallagher.com/micro-clearfix-hack/]
 */
  /* Techniques that make content inaccessible (invisible to all users).
* [https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html]*/
  /* slick slider */
  /* 
  * BOOTSTRAP 4 reset
  */
}

html ol,
html ul,
html dl {
  margin-bottom: 0;
}

html ::selection {
  color: white;
  background: #206cd7;
}

html a:hover {
  text-shadow: 0 0 0;
  color: inherit;
  text-decoration: none;
}

html .btn {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 13px;
}

html a,
html img,
html .btn {
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

html select,
html textarea,
html [type='text'],
html [type='password'],
html [type='datetime'],
html [type='datetime-local'],
html [type='date'],
html [type='month'],
html [type='time'],
html [type='week'],
html [type='number'],
html [type='email'],
html [type='url'],
html [type='search'],
html [type='tel'] {
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

html select:hover,
html textarea:hover,
html [type='text']:hover,
html [type='password']:hover,
html [type='datetime']:hover,
html [type='datetime-local']:hover,
html [type='date']:hover,
html [type='month']:hover,
html [type='time']:hover,
html [type='week']:hover,
html [type='number']:hover,
html [type='email']:hover,
html [type='url']:hover,
html [type='search']:hover,
html [type='tel']:hover {
  box-shadow: 0 0 0 1px #ddd, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

html select:focus,
html textarea:focus,
html [type='text']:focus,
html [type='password']:focus,
html [type='datetime']:focus,
html [type='datetime-local']:focus,
html [type='date']:focus,
html [type='month']:focus,
html [type='time']:focus,
html [type='week']:focus,
html [type='number']:focus,
html [type='email']:focus,
html [type='url']:focus,
html [type='search']:focus,
html [type='tel']:focus {
  box-shadow: 0 0 0 2px #206cd7, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

html .form-control {
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

html .form-control:hover {
  box-shadow: 0 0 0 1px #ddd, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

html .form-control:focus {
  box-shadow: 0 0 0 2px #206cd7, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

html input[type="checkbox"],
html input[type="radio"] {
  box-shadow: none !important;
}

html .btn,
html button,
html input,
html select,
html textarea,
html .form-control,
html .styled-select,
html .list.-menu > li > a {
  padding: 0 1rem;
  line-height: 2rem;
  height: 2rem;
  border: 0 none;
  border-radius: 4px;
}

html .badge {
  border-radius: 4px;
  transition: all 0.24s cubic-bezier(0, 0, 0.58, 1);
  transform: translate3d(0, 0, 0);
}

html input,
html select,
html textarea,
html .form-control {
  font-size: 13px;
}

html .btn.-small,
html .input.-small,
html select.-small,
html .form-control.-small,
html .list.-menu.-small > li a {
  padding: 0 0.75rem;
  line-height: 1.5rem;
  height: 1.5rem;
}

html .btn.-medium,
html .list.-menu.-medium > li a {
  padding: 0 1.5rem;
  line-height: 2.5rem;
  height: 2.5rem;
}

html input.-medium,
html textarea.-medium,
html .form-control.-medium {
  padding: 0 1.15rem;
  line-height: 2.5rem;
  height: 2.5rem;
}

html .btn.-large,
html .list.-menu.-large > li a {
  padding: 0 2rem;
  line-height: 3.5rem;
  height: 3.5rem;
}

html input.-large,
html select.-large,
html .form-control.-large {
  padding: 0 1.4rem;
  line-height: 3.5rem;
  height: 3.5rem;
}

html textarea {
  height: auto;
  min-height: 80px;
  line-height: 1.61803;
}

@media only screen and (min-width: 1024px) {
  html .btn.-small {
    font-size: .7rem;
  }
  html .btn.-medium {
    font-size: 1.1rem;
  }
  html .btn.-large {
    font-size: 1.3rem;
  }
}

html ::-webkit-input-placeholder {
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  font-weight: 600;
}

html ::-moz-placeholder {
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  font-weight: 600;
}

html :-ms-input-placeholder {
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  font-weight: 600;
}

html input::-webkit-input-placeholder {
  opacity: .75;
}

html textarea::-webkit-input-placeholder {
  opacity: .75;
}

html input:-moz-placeholder {
  opacity: .75;
}

html textarea:-moz-placeholder {
  opacity: .75;
}

html input:-ms-input-placeholder {
  opacity: .75;
}

html textarea:-ms-input-placeholder {
  opacity: .75;
}

html input:hover::-webkit-input-placeholder {
  opacity: .65;
  text-shadow: 0 0 0;
}

html textarea:hover::-webkit-input-placeholder {
  opacity: .65;
  text-shadow: 0 0 0;
}

html input:focus::-webkit-input-placeholder {
  transition-delay: 0.5s;
  opacity: .5;
}

html textarea:focus::-webkit-input-placeholder {
  transition-delay: 0.5s;
  opacity: .5;
}

html input:hover:-moz-placeholder {
  opacity: .65;
  text-shadow: 0 0 0;
}

html textarea:hover:-moz-placeholder {
  opacity: .65;
  text-shadow: 0 0 0;
}

html input:focus:-moz-placeholder {
  transition-delay: 0.5s;
  opacity: .5;
}

html textarea:focus:-moz-placeholder {
  transition-delay: 0.5s;
  opacity: .5;
}

html input:hover:-ms-input-placeholder {
  opacity: .65;
  text-shadow: 0 0 0;
}

html textarea:hover:-ms-input-placeholder {
  opacity: .65;
  text-shadow: 0 0 0;
}

html input:focus:-ms-input-placeholder {
  transition-delay: 0.5s;
  opacity: .5;
}

html textarea:focus:-ms-input-placeholder {
  transition-delay: 0.5s;
  opacity: .5;
}

html .styled-select {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 !important;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: none !important;
  border: 0 none !important;
}

html .styled-select select option[selected] {
  cursor: default;
  color: black;
  background-color: #f3f3a0;
  text-shadow: 0 0 0;
}

html .styled-select select {
  display: block;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  padding: 0 2rem 0 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
}

html .styled-select::after {
  content: "\f107";
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  opacity: 0.8;
  margin-right: 0.5rem;
  font-size: 1.25rem;
  font-family: FontAwesome;
  font-weight: 400;
  font-style: normal;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: inherit;
  line-height: inherit;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-drag: none;
}

html .text-underline {
  text-decoration: underline;
}

html .cf::before,
html .cf::after,
html .clearfix::before,
html .clearfix::after {
  display: table;
  content: " ";
}

html .cf::after,
html .clearfix::after {
  clear: both;
}

html .main-content,
html .main-header,
html .main-footer {
  clear: both;
}

html .embed-responsive.-anime img {
  transform: scale(1);
  transition: all 0.4s cubic-bezier(0, 0, 0.58, 1);
  transform: translate3d(0, 0, 0);
}

html .embed-responsive.-anime:hover img {
  transform: scale(1.2);
}

html .bg-image {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
}

html .bg-image.-fixed {
  background-attachment: fixed;
  background-position-y: bottom;
}

html .line-clamp,
html .line-clamp-2,
html .line-clamp-3,
html .line-clamp-4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

html .line-clamp-2 {
  -webkit-line-clamp: 2;
}

html .line-clamp-3 {
  -webkit-line-clamp: 3;
}

html .line-clamp-4 {
  -webkit-line-clamp: 4;
}

html .invisible,
html .visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
}

html :root:hover .invisible *,
html :root:hover .visually-hidden * {
  display: none !important;
}

html .truncate,
html .ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

html .vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-filter: alpha opacity=0;
    -moz-filter: alpha opacity=0;
    filter: alpha opacity=0;
  }
}

html .fade-in {
  -webkit-animation: fadeIn 1s normal none;
  animation: fadeIn 1s normal none;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

html .fade-in.-xs {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

html .fade-in.-s {
  -webkit-animation-duration: 1500ms;
  animation-duration: 1500ms;
}

html .fade-in.-m {
  -webkit-animation-duration: 2000ms;
  animation-duration: 2000ms;
}

html .fade-in.-l {
  -webkit-animation-duration: 2500ms;
  animation-duration: 2500ms;
}

html .fade-in.-xl {
  -webkit-animation-duration: 3000ms;
  animation-duration: 3000ms;
}

html .fade-in.-xxl {
  -webkit-animation-duration: 3500ms;
  animation-duration: 3500ms;
}

html .cover {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

html .media .cover {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 4rem;
  flex: 1 0 4rem;
  max-width: 4rem;
}

html .list-media .media .cover {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;
  max-width: 25%;
}

html .cover::before {
  display: block;
  padding-top: 100%;
  content: "";
}

html .cover img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: auto;
  max-width: none;
  height: 100%;
  border: 0;
}

html .cover::after {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  content: "";
  opacity: 0;
  background-image: -webkit-gradient(linear, right top, left top, from(#434c63), to(#bc9b72));
  background-image: -webkit-linear-gradient(right, #434c63, #bc9b72);
  background-image: -o-linear-gradient(right, #434c63, #bc9b72);
  background-image: linear-gradient(to left, #434c63, #bc9b72);
}

html .cover img,
html .cover::after {
  -webkit-transition: all 0.24s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.24s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.24s cubic-bezier(0, 0, 0.58, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html a.cover::after {
  font-family: "fonticon";
  font-size: 2rem;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  content: "\e92d";
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  color: white;
}

html .cover:hover::after {
  font-size: 2.25rem;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: .85;
}

html .cover:hover img {
  -webkit-transition: all 2s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 2s cubic-bezier(0, 0, 0.58, 1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

html .cover img,
html .cover::after {
  -webkit-transition: all 0.24s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.24s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.24s cubic-bezier(0, 0, 0.58, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html .embed-content {
  position: absolute;
  z-index: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

html .embed-content.-caption {
  z-index: 20;
}

html .embed-content.-top {
  top: 0;
  bottom: auto;
  height: auto;
  padding: .5rem;
}

html .embed-content.-bottom {
  top: auto;
  bottom: 0;
  height: auto;
  padding: .5rem;
}

html .embed-content.-middle {
  top: 50%;
  bottom: auto;
  height: auto;
  padding: .5rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

html .carousel,
html .slick-slider {
  position: relative;
  z-index: auto;
  clear: both;
}

html .slide-control {
  font-size: 4rem;
  line-height: 3rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  width: 4rem;
  height: 4rem;
  padding: 0;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  transform: translateY(-50%);
  opacity: 1;
  color: #333;
  border: 0 none;
  border-radius: 0;
}

html .slide-control .fa {
  position: relative;
  color: #cecece;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

html .slide-control.-prev {
  right: 100%;
}

html .slide-control.-next {
  left: 100%;
}

html .slide-control.-prev:hover .fa {
  transform: translateX(-0.5rem);
}

html .slide-control.-next:hover .fa {
  transform: translateX(0.5rem);
}

html .slick-dots {
  position: relative;
}

html .slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
}

html .slick-dots li + li {
  margin-left: 1rem;
}

html .slick-dots li button {
  width: 12px;
  height: 12px;
  margin: 0;
  border-width: 1px;
  border-radius: 0;
  background-color: #e7e7e7;
}

html .slick-dots li button::before {
  display: none;
  content: "";
}

html .slick-dots button:hover,
html .slick-dots .slick-active button {
  background-color: #162882;
}

html .slick-dots button:hover::after,
html .slick-dots .slick-active button::after {
  position: relative;
  z-index: 1;
  top: -15px;
  bottom: 100%;
  display: block;
  width: 10px;
  height: 10px;
  margin-left: -4px;
  content: "";
  opacity: 1;
  border: 5px solid transparent;
  border-bottom: 5px solid #162882;
  background: none !important;
}

html .slick-hidden.-arrows .slick-dots,
html .slick-hidden.-controls .slide-control {
  display: none !important;
  visibility: hidden !important;
}

html .slick-dotted.slick-slider.slick-hidden.-controls {
  margin-bottom: 0;
}

html .form.-grey .form-control {
  border: 0 none;
  border-radius: 0;
  background-color: #f3f3f3;
}

html .form.-grey .form-control:not(:hover):not(:focus) {
  box-shadow: none;
}

html .form-control.form-control-lg {
  font-size: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

html .form.-white .form-control {
  border: 0 none;
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: inset 0 -2px 0 0 #e7e7e7;
  box-shadow: inset 0 -2px 0 0 #e7e7e7;
}

html .form.-white .form-control:hover {
  -webkit-box-shadow: inset 0 -2px 0 0 #162882;
  box-shadow: inset 0 -2px 0 0 #162882;
}

html .form.-white .form-control:focus {
  background-color: #f7f7f7;
  -webkit-box-shadow: inset 0 -2px 0 0 #162882;
  box-shadow: inset 0 -2px 0 0 #162882;
}

html .form.-hidden-labels label {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

html form div.error {
  font-size: .8rem;
  line-height: 2;
  display: block;
  clear: both;
  -webkit-animation: fadeIn 1s normal none;
  animation: fadeIn 1s normal none;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  white-space: normal;
  color: #e23535;
}

html form .text-danger,
html form .text-success {
  font-size: .85rem;
  line-height: 2;
}

html input.error,
html select.error,
html textarea.error {
  color: #dc7878;
  background-color: #ffe4e4;
  -webkit-box-shadow: inset 0 0 0 1px #dc7878;
  box-shadow: inset 0 0 0 1px #dc7878;
}

html input.success,
html select.success,
html textarea.success {
  color: #49b381;
  background-color: #ebfff9;
  -webkit-box-shadow: inset 0 0 0 1px #49b381;
  box-shadow: inset 0 0 0 1px #49b381;
}

html .styled-list {
  padding-left: 1rem;
  list-style-type: disc;
  list-style-position: outside;
  font-size: 1rem;
}

html .dropdown-menu {
  font-size: inherit;
  animation: animeDropdown 0.36s 0s normal both;
  animation-timing-function: cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

html b,
html option,
html strong {
  font-weight: 700;
}

html .lead {
  font-size: 1rem;
}

html .navbar {
  padding-right: 0;
  padding-left: 0;
}

html .nav-pills .nav-link {
  border-radius: 0;
}

html .btn-primary {
  color: white;
}

html .list-menu li {
  line-height: 2rem;
}

html .fs-normal {
  font-size: 1rem;
}

html .fs-small {
  font-size: small;
}

html .fs-medium {
  font-size: 1.25rem;
}

html .fs-title {
  font-size: 1.75rem;
}

html .form-group:last-child,
html p:last-child,
html h1:last-child,
html .h1:last-child,
html h2:last-child,
html .h2:last-child,
html h3:last-child,
html .h3:last-child,
html h4:last-child,
html .h4:last-child,
html h5:last-child,
html .h5:last-child,
html h6:last-child,
html .h6:last-child {
  margin-bottom: 0;
}

html .pagination {
  font-family: "Lora";
  font-weight: normal;
  font-style: italic;
}

html .pagination .page-item + .page-item {
  margin-left: .5rem;
}

html .pagination .page-link {
  border: 0 none !important;
  border-radius: 0 !important;
}

html .pagination .page-item:not(.active) .page-link {
  color: #606060;
  -webkit-box-shadow: inset 0 0 0 2px #f3f3f3;
  box-shadow: inset 0 0 0 2px #f3f3f3;
}

html .modal-open .modal {
  background-color: rgba(0, 0, 0, 0.33);
}

html .modal-backdrop {
  display: none !important;
}

html .btn-success {
  color: white;
}

html .fa {
  vertical-align: top;
  display: inline-block;
  line-height: 1;
}

html .embed-responsive img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

html .list-group-item {
  background-color: transparent;
}

html .list-group-flush .list-group-item + .list-group-item {
  border: 0;
  border-top: 1px dotted #ddd;
}

html .pagination-wrapper {
  display: flex;
  justify-content: center;
}

@media (min-width: 0) and (max-width: 576px) {
  html .pagination-wrapper {
    padding-top: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  html .pagination-wrapper {
    padding-top: 1.5rem;
  }
}

@media (min-width: 993px) {
  html .pagination-wrapper {
    padding-top: 2rem;
  }
}

html .pagination-wrapper .page-link {
  box-shadow: none !important;
  background: transparent !important;
  line-height: 1;
  font-size: 1rem;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  letter-spacing: -0.61803px;
  word-spacing: .25rem;
}

html .pagination-wrapper .page-link:hover {
  color: #206cd7 !important;
}

html .pagination-wrapper .page-link .fa {
  font-size: 1.5rem;
  line-height: 0.53;
  vertical-align: top;
}

html .pagination-wrapper .active .page-link {
  color: #206cd7 !important;
}

html .tooltip {
  font-weight: bold;
}

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-size: 1rem;
}

@media (min-width: 0) and (max-width: 576px) {
  body {
    line-height: 1.5;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  body {
    line-height: 1.4;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  body {
    line-height: 1.3;
  }
}

@media (min-width: 993px) {
  body {
    line-height: 1.2;
    font-size: 14px;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  html .section {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  html .section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 993px) {
  html .section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.title-dash {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.title-dash::before {
  position: absolute;
  width: calc(100% + 10px);
  left: -5px;
  right: -5px;
  top: 50%;
  content: " ";
  z-index: -1;
  border-top-width: 4px;
  border-top-style: solid;
}

.title-dash.-livros::before {
  color: #d667c8;
}

.title-dash.-questoes::before {
  color: #ffc965;
}

.title-dash.-destaques::before {
  color: #ffda95;
}

.title-dash.-noticias::before {
  color: #bbbbbb;
}

.title-dash.-depoimentos::before {
  color: #c3c6e9;
}

.title-dash.-cursos::before {
  color: #0fb970;
}

.title-dash.-c-cadernos-de-questoes::before {
  color: #7a000d;
}

.title-dash.-provas::before {
  color: #80acff;
}

.title-dash.-erratas::before {
  color: #f86a60;
}

.section-hero {
  text-align: center;
}

@media (min-width: 0) and (max-width: 576px) {
  .section-hero {
    margin-bottom: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .section-hero {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 993px) {
  .section-hero {
    margin-bottom: 2rem;
  }
}

.section-title {
  margin-bottom: 0;
  color: #232323;
  font-weight: bold;
}

.section-title.-main {
  color: #162882;
}

@media (min-width: 0) and (max-width: 576px) {
  .section-title {
    font-size: 1.75rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .section-title {
    font-size: 2.25rem;
  }
}

@media (min-width: 993px) {
  .section-title {
    font-size: 38px;
  }
}

.section-subtitle {
  font-family: 'Lora', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: normal;
  font-style: italic;
  color: #aaa;
}

@media (min-width: 0) and (max-width: 576px) {
  .section-subtitle {
    font-size: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .section-subtitle {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .section-subtitle {
    font-size: 1.5rem;
  }
}

@media (min-width: 993px) {
  .section-subtitle {
    font-size: 18px;
  }
}

.main-content {
  background-image: url(../img/bg-page.png);
  background-position: left top;
  background-repeat: no-repeat;
}

.dropdown-menu.show {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
}

.box-card {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
}

.box-card:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

#html .btn-light {
  color: #444;
}

#html .btn-grey {
  color: #444;
}

#html .btn-grey:hover {
  background-color: #f0f0f0;
}

.btn-border-bottom {
  box-shadow: inset 0 -2px 0 0 #bbbbbb;
  border-radius: 0 !important;
}

.btn-border-bottom:hover, .btn-border-bottom:active {
  background-color: #bbbbbb;
  color: white;
}

#html .float-button-whatsapp {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1020;
  padding: 0;
  border-radius: 50%;
  width: 3.3rem;
  height: 3.3rem;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 15px 0 rgba(0, 0, 0, 0.16), 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

@media (min-width: 0) and (max-width: 576px) {
  #html .float-button-whatsapp {
    margin: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  #html .float-button-whatsapp {
    margin: 1.5rem;
  }
}

@media (min-width: 993px) {
  #html .float-button-whatsapp {
    margin: 2rem;
  }
}

#html .float-button-whatsapp:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
}

#html .float-button-whatsapp:focus {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
}

#html .float-button-whatsapp:hover {
  transform: translateY(-3px);
}

.main-content .nav-item:hover, .main-content .nav-item.active {
  color: #162882;
}

.nav-item.-cursos-online:hover, .nav-item.-cursos-online.active {
  color: #0fb970;
}

.nav-item.-cadernos-de-questoes:hover, .nav-item.-cadernos-de-questoes.active {
  color: #7a000d;
}

.nav-item.-apostilas:hover, .nav-item.-apostilas.active {
  color: #fd8f36;
}

.nav-item.-livros:hover, .nav-item.-livros.active {
  color: #d667c8;
}

.nav-item.-questoes-de-concursos:hover, .nav-item.-questoes-de-concursos.active {
  color: #ffc965;
}

.nav-item.-provas:hover, .nav-item.-provas.active {
  color: #80acff;
}

.nav-item.-noticias:hover, .nav-item.-noticias.active {
  color: #bbbbbb;
}

.nav-item.-erratas-e-redificacao:hover, .nav-item.-erratas-e-redificacao.active {
  color: #f86a60;
}

.hr-divider {
  display: flex;
  align-itemPixes: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.hr-divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: -1;
  display: block;
  height: 1px;
  background-color: #ddd;
}

.hr-divider span {
  background-color: #f8f8fa;
  padding: 0.1rem 0.5rem;
  text-transform: uppercase;
}

.form-product-filter {
  float: right;
  position: relative;
}

.form-product-filter .form-row {
  align-itemPixes: center;
  flex-flow: row nowrap;
}

@media (max-width: 767px) {
  .form-product-filter .form-row {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .form-product-filter .form-row {
    justify-content: flex-end;
  }
}

.form-product-filter .form-group {
  margin-bottom: 0;
}

.form-product-filter .form-group + .form-group {
  margin-left: 0.75rem;
}

.form-product-filter .btn-link {
  padding: 0 0.5rem;
  color: inherit;
}

.form-product-filter select.form-control {
  max-width: 8rem;
}

@media (max-width: 767px) {
  .form-product-filter select.form-control {
    padding: 0 0.5rem;
  }
}

.form-group.-product-filter {
  position: relative;
  white-space: nowrap;
}

.form-group.-product-filter .label-product-filter {
  display: flex;
  align-itemPixes: center;
  padding: 0;
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 0;
}

.form-group.-product-filter .label-product-filter img {
  vertical-align: top;
  margin-right: 0.5rem;
}

.form-group.-product-filter .label-product-filter .fa {
  margin-left: 0.5rem;
}

.form-group.-product-filter .form-control {
  position: absolute;
  opacity: 0;
  z-index: 2;
  padding: 0;
}

.form-group.-product-filter .form-control:focus {
  opacity: 1;
}

#homeSlider {
  color: white;
  text-align: center;
}

#homeSlider .title-dash::before {
  border-top-width: 8px;
}

#homeSlider .title {
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media (min-width: 0) and (max-width: 768px) {
  #homeSlider .title {
    font-size: 2rem;
  }
}

@media (min-width: 993px) {
  #homeSlider .title {
    font-size: 3.75rem;
  }
}

#homeSlider .subtitle {
  line-height: 1.5;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.333);
  font-size: 1.3rem;
}

@media (min-width: 0) and (max-width: 768px) {
  #homeSlider .subtitle {
    white-space: normal;
    min-width: 90vw;
    margin-top: 2rem;
    line-height: 1.2;
  }
}

.main-header {
  background-color: #f8f8fa;
}

.main-header.is-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  animation: animeStickyHeader 0.618s 0s normal both;
  animation-timing-function: cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

.main-header.is-fixed .header-middle-bar {
  padding: 0.5rem 0;
  background-color: white !important;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.03), 0 4px 0 0 rgba(0, 0, 0, 0.02);
}

.main-header.is-fixed .logo {
  max-height: 3rem !important;
}

@media (min-width: 0) and (max-width: 992px) {
  .main-header.is-fixed .menu-search,
  .main-header.is-fixed .menu-ecommerce,
  .main-header.is-fixed .header-top-bar,
  .main-header.is-fixed .header-bottom-bar {
    display: none !important;
  }
}

@media (min-width: 993px) {
  .main-header.is-fixed .header-top-bar,
  .main-header.is-fixed .header-bottom-bar {
    display: none !important;
  }
}

.header-top-bar {
  background-color: #162882;
  color: white;
  font-size: 12px;
}

.header-top-bar .navbar-nav {
  align-itemPixes: center;
}

.header-top-bar .navbar-nav a {
  padding-top: 0;
  padding-bottom: 0;
}

.header-top-bar .social-list .fa {
  font-size: 1.25rem;
}

.header-top-bar .social-list .nav-item.-whatsapp {
  color: #0db870;
  margin-right: 1rem;
}

@media (max-width: 991px) {
  .header-middle-bar {
    padding: 0.5rem 0;
  }
}

@media (min-width: 992px) {
  .header-middle-bar {
    padding: 1rem 0;
    background-color: transparent;
  }
}

.header-middle-bar .main-menu-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-itemPixes: center;
}

.header-middle-bar .menu-logo {
  align-self: flex-start;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-itemPixes: center;
}

.header-middle-bar .menu-logo .logo {
  max-height: 3rem;
  width: auto;
}

@media (min-width: 992px) {
  .header-middle-bar .menu-logo {
    width: auto;
  }
  .header-middle-bar .menu-logo .logo {
    max-height: none;
    max-width: 128px;
  }
}

.header-middle-bar .btn-toggle-mobilemenu {
  line-height: 3rem;
  height: 3rem;
  padding: 0;
  border-radius: 0;
  text-align: center;
  background-color: transparent;
}

.header-middle-bar .btn-toggle-mobilemenu::after {
  content: "Menu";
  display: inline-block;
  color: #162882;
  font-weight: bold;
  margin-left: 0.5rem;
}

.header-middle-bar .btn-toggle-mobilemenu img {
  width: 2rem;
}

.header-middle-bar .btn-main-menu {
  cursor: pointer;
  border-radius: 50%;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

.header-middle-bar .btn-main-menu:hover {
  box-shadow: 0 0 0 1px #ddd, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.header-middle-bar .btn-main-menu:focus {
  box-shadow: 0 0 0 2px #206cd7, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.header-middle-bar .btn-main-menu::after {
  display: none;
}

.header-middle-bar .menu-hamburguer {
  align-itemPixes: center;
}

.header-middle-bar .menu-hamburguer .dropdown-menu {
  font-size: 13px;
  line-height: 1;
  min-width: 12rem;
  margin-top: 1rem;
}

.header-middle-bar .menu-hamburguer .dropdown-menu::after, .header-middle-bar .menu-hamburguer .dropdown-menu::before {
  bottom: 100%;
  left: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.header-middle-bar .menu-hamburguer .dropdown-menu::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.header-middle-bar .menu-hamburguer .dropdown-menu::before {
  border-color: rgba(238, 238, 238, 0);
  border-bottom-color: #efefef;
  border-width: 13px;
  margin-left: -13px;
}

.header-middle-bar .menu-hamburguer .dropdown-menu .nav-link:hover {
  background-color: #162882;
  color: white;
}

.header-middle-bar .menu-hamburguer strong.c-main {
  display: inline-block;
  margin-left: 0.25rem;
}

.header-middle-bar .menu-search {
  position: relative;
}

@media (max-width: 991.98px) {
  .header-middle-bar .menu-search {
    width: 100%;
    margin: 1rem 0;
  }
}

.header-middle-bar .menu-search .form-control {
  width: 100%;
  line-height: 2.5rem;
  height: 2.5rem;
}

@media (min-width: 992px) {
  .header-middle-bar .menu-search .form-control {
    min-width: 400px;
  }
}

.header-middle-bar .menu-search .label {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.75rem 1rem;
  line-height: 1;
  margin: 0;
}

.header-middle-bar .btn-wishlist {
  font-weight: 700;
  font-weight: bold;
  display: flex;
  align-itemPixes: center;
  white-space: nowrap;
}

.header-middle-bar .btn-wishlist .fa {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.header-middle-bar .menu-ecommerce {
  display: flex;
  align-itemPixes: center;
}

.header-middle-bar .menu-ecommerce .item + .item {
  margin-left: 2rem;
}

@media (max-width: 991.98px) {
  .header-middle-bar .menu-ecommerce {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.header-middle-bar .menu-ecommerce .btn-login {
  display: flex;
  align-itemPixes: center;
}

.header-middle-bar .menu-ecommerce .btn-login img {
  margin-right: 0.35rem;
}

.header-middle-bar .menu-ecommerce .btn-cart {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

@media (max-width: 991.98px) {
  .header-middle-bar .menu-ecommerce .btn-cart {
    margin: 0 1rem 0 0;
  }
}

.header-middle-bar .menu-ecommerce .btn-cart:hover img {
  opacity: 0.8;
}

.header-middle-bar .menu-ecommerce .btn-cart:hover .badge {
  top: -7px;
}

.header-middle-bar .menu-ecommerce .btn-cart img {
  width: 2.25rem;
}

.header-middle-bar .menu-ecommerce .badge {
  border-radius: 1rem;
  position: absolute;
  top: -5px;
  left: 100%;
  transform: translateX(-50%);
  box-shadow: 0 0 0 3px #f8f8fa;
  line-height: 1.25rem;
  width: 1.25rem;
  padding: 0;
}

.header-bottom-bar {
  background-color: white;
}

.header-bottom-bar .main-navigation {
  justify-content: space-between;
  padding: 0;
  font-weight: 600;
}

.header-bottom-bar .main-navigation .nav-link {
  position: relative;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  padding: 0 1rem;
  line-height: 2.5rem;
}

.header-bottom-bar .main-navigation .nav-link:hover, .header-bottom-bar .main-navigation .nav-link.active {
  border-top-color: inherit;
}

.header-bottom-bar .main-navigation .nav-item.-home a::before {
  margin-right: 7px;
  content: url(../img/svg-institucional.svg);
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  top: -3px;
}

.header-bottom-bar .main-navigation .nav-item.-home:not(:hover) a::before {
  filter: grayscale(1);
}

.main-footer {
  font-size: 13px;
  background-color: #f8f8fa;
  position: relative;
  width: 100%;
  bottom: 0;
}

.main-footer .link-ilion:hover .logo-ilion {
  transform: translateY(-2px);
}

.main-footer .logo-ilion {
  margin-top: 3rem;
}

.institucional-bar .title.-footer {
  font-size: 1rem;
  color: #162882;
}

.institucional-bar .title.-footer img {
  margin-right: 0.5rem;
  vertical-align: bottom;
}

.institucional-bar .list {
  color: #888;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .institucional-bar .list {
    margin-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .institucional-bar .list {
    padding-left: 2rem;
  }
}

.institucional-bar .list .nav-link {
  padding: 0;
}

.institucional-bar .list.-social {
  padding: 0;
  justify-content: flex-start;
}

.institucional-bar .list.-social .nav-link {
  padding: 0 0.5rem;
  font-size: 2rem;
}

.institucional-bar .list .footer-horario {
  line-height: 1.5;
  margin-top: 2rem;
}

.institucional-bar .list .footer-horario strong {
  color: #162882;
  display: block;
}

.institucional-bar .footer-alerta {
  display: flex;
  align-itemPixes: center;
}

.institucional-bar .footer-alerta strong {
  color: #fd7a0f;
}

.institucional-bar .footer-alerta img {
  margin-right: 1rem;
}

.institucional-bar .form-newsletter.-footer {
  margin-top: 3rem;
  position: relative;
}

.institucional-bar .form-newsletter.-footer .form-control {
  width: 100%;
  height: 2.75rem;
  line-height: 2.75rem;
  padding-right: 3rem;
}

.institucional-bar .form-newsletter.-footer .btn-submit {
  position: absolute;
  right: 0;
  top: 0;
  height: 2.75rem;
  line-height: 2.75rem;
  background-color: transparent;
}

.institucional-bar .form-newsletter.-footer .btn-submit:hover {
  background-color: #162882;
  color: white;
}

@media (max-width: 991.98px) {
  .institucional-bar .footer-site-seguro {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
}

.copyright-bar {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #162882;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
}

.copyright-bar strong {
  color: white;
}

.copyright-bar .row {
  align-itemPixes: center;
}

@media (max-width: 991.98px) {
  .copyright-bar .copyright-company {
    margin-bottom: 2rem;
  }
  .copyright-bar .copyright-company figure {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .copyright-bar .copyright-company {
    display: flex;
  }
}

.copyright-bar .copyright-company figure {
  margin-right: 2rem;
}

@media (max-width: 991.98px) {
  .copyright-bar .footer-address strong {
    display: block;
    margin-top: 2rem;
  }
}

.copyright-bar .list-copyright-contacts {
  white-space: nowrap;
}

.copyright-bar .footer-address {
  font-weight: inherit;
}

.page-title {
  background-color: white;
  padding-top: 1rem;
}

.page-title .breadcrumb {
  background-color: inherit;
  padding: 0 0 1rem 0;
  margin: 0;
  line-height: 1;
}

.page-title .breadcrumb li {
  display: flex;
  align-itemPixes: center;
  flex-flow: row nowrap;
}

.page-title .breadcrumb h1 {
  font-size: inherit;
  display: inline-block;
}

.page-title .title {
  font-weight: bold;
  font-size: 2rem;
  color: #232323;
  margin-bottom: 0;
  line-height: 1;
}

.page-title .title.-main {
  color: #162882;
}

.page-title .subtitle {
  color: #aaa;
  font-weight: 600;
  margin-top: 0.5rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .page-title .form-product-filter {
    margin-top: 1rem;
  }
}

.page-title .hr-page-title {
  margin-bottom: 0;
  background-color: #eeeeee;
  display: none;
}

.page-title .container:last-child::after {
  content: " ";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #eeeeee;
  clear: both;
  overflow: hidden;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .page-title .container:last-child::after {
    margin-top: 2rem;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-produto.carousel .slick-slide {
    padding: 0.5rem;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-produto > li {
    padding: 1rem 3rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .listagem-produto > li {
    padding: 1rem;
  }
}

@media (min-width: 993px) {
  .listagem-produto > li {
    padding: 1rem;
  }
}

.listagem-produto .product {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
}

.listagem-produto .product:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

@media (min-width: 993px) {
  .listagem-produto .product {
    height: 100%;
  }
}

.listagem-produto .product .product-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.listagem-produto .product .product-embed::before {
  padding-top: 100%;
  display: block;
  content: " ";
}

.listagem-produto .product .product-embed a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-itemPixes: center;
  justify-content: center;
}

.listagem-produto .product .product-embed .img-product {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}

.listagem-produto .product .title.-name {
  font-size: 1rem;
  color: #242424;
  margin: 1.5rem 0;
  word-break: break-word;
}

.listagem-produto .product .product-actions {
  display: flex;
  justify-content: space-between;
}

.listagem-produto .product .product-actions .btn {
  border-radius: 1rem;
}

.listagem-produto .product .product-actions .btn.btn-price {
  color: #f12b2b;
  font-size: 1rem;
}

.listagem-produto .product.-curso-online {
  background-color: #f8f8f8;
  background-color: #f8f8fa;
  box-shadow: none;
  padding: 0;
}

.listagem-produto .product.-curso-online .product-embed {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.listagem-produto .product.-curso-online .product-embed::before {
  padding-top: 56.25%;
}

.listagem-produto .product.-curso-online .product-embed .img-product {
  width: 100%;
  max-width: none;
  max-height: none;
}

.listagem-produto .product.-curso-online .product-info {
  padding: 1.5rem;
}

.listagem-produto .product.-curso-online .product-info .title {
  margin: 0;
}

.listagem-produto .product.-curso-online .product-price {
  color: #f12b2b;
  font-size: 1rem;
  margin-top: 1rem;
  line-height: 1.1;
  font-weight: 600;
}

.produtos-relacionados {
  box-shadow: inset 0 1px 0 #eee;
}

.product {
  position: relative;
}

.product .title.-name {
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  line-height: 1.1;
}

.product .product-price {
  color: #fd7a0f;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}

.product .product-price small {
  font-weight: 500;
}

.product .btn-buy {
  display: flex;
  justify-content: space-between;
  align-itemPixes: center;
}

.product .btn-buy img {
  width: 1.25rem;
}

.product .btn-wishlist {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
  background-color: transparent;
  color: #f12b2b;
  z-index: 10;
  padding: 0;
  font-size: 1.5rem;
}

.product .btn-wishlist:hover .fa::before {
  content: "\f004";
}

.product .product-article {
  margin-top: 3rem;
}

.product .product-article .title {
  font-size: 1.25rem;
  font-weight: 600;
}

.product .product-article p {
  font-size: 1rem;
}

.product .form-product-buy fieldset {
  padding: 2rem;
  background-color: #f8f8fa;
  border-radius: 5px;
  color: #999;
  line-height: 1;
}

.product .form-product-buy .btn-buy {
  line-height: 4rem;
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.25rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.product .variations .variation {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2rem;
}

.product .variations .variation:hover .fa::before {
  content: "\f046";
}

.product .variations .variation label {
  margin: 0;
  cursor: pointer;
  display: block;
}

.product .variations .variation .product-price {
  margin-bottom: 0;
}

.product .variations .variation .fa {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 1.5rem;
  line-height: 0.8090;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

.product .variations .variation input:checked + .fa {
  color: #0db870;
}

.product .variations .variation input:checked + .fa::before {
  content: "\f046";
}

.product .list-share {
  display: flex;
  justify-content: center;
  align-itemPixes: center;
  font-weight: 600;
  margin-top: 1rem;
}

.product .list-share .fa {
  font-size: 1.5rem;
}

.product .product-list-features {
  display: flex;
  flex-wrap: wrap;
}

.product .product-list-features .feature .title {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.product .product-list-features .feature .subtitle {
  line-height: 1.3;
}

.product .product-showcase .title {
  font-size: 1.75rem;
  color: #232323;
  margin-bottom: 2rem;
  font-weight: bold;
}

.product .product-showcase table td {
  padding: 0.25rem 1rem;
}

.product .product-showcase table td:first-child {
  width: 25%;
}

.product.curso .embed-responsive {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-noticia > li {
    padding: 1rem 3rem;
  }
}

.news figcaption {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
}

.news figcaption:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

.news .news-img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  z-index: auto;
}

.news .news-title {
  margin: 0.5rem 0;
  word-break: break-word;
  color: #242424;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

@media (min-width: 0) and (max-width: 576px) {
  .news .news-title {
    font-size: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .news .news-title {
    font-size: 1.15rem;
  }
}

@media (min-width: 993px) {
  .news .news-title {
    font-size: 1.125rem;
  }
}

.depoimentos.-home .section-title .title-dash::after {
  display: inline-block;
  content: url(../img/svg-aspas.svg);
  position: absolute;
  margin: -1.5rem 1rem;
}

.depoimentos.-home .container-fluid {
  padding: 0;
}

.depoimentos.-home .slick-slide {
  padding: 0;
}

.depoimentos.-home .slick-slide.slick-current .depoimento-caption {
  background-color: rgba(32, 108, 215, 0.95);
}

.listagem-depoimento .slide-control.-prev {
  left: 0;
  right: auto;
}

.listagem-depoimento .slide-control.-next {
  right: 0;
  left: auto;
}

.depoimento {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
}

.depoimento::before {
  padding-top: 100%;
  display: block;
  content: " ";
}

.depoimento:hover .depoimento-caption {
  background-color: rgba(32, 108, 215, 0.95);
}

.depoimento .depoimento-caption {
  background-color: rgba(22, 40, 130, 0.95);
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

@media (min-width: 0) and (max-width: 576px) {
  .depoimento .depoimento-caption {
    width: 80%;
    padding: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .depoimento .depoimento-caption {
    width: 80%;
    padding: 1.5rem;
  }
}

@media (min-width: 993px) {
  .depoimento .depoimento-caption {
    width: 60%;
    padding: 2rem;
  }
}

.depoimento .depoimento-title {
  font-weight: bold;
}

@media (min-width: 0) and (max-width: 576px) {
  .depoimento .depoimento-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .depoimento .depoimento-title {
    font-size: 1.75rem;
  }
}

@media (min-width: 993px) {
  .depoimento .depoimento-title {
    font-size: 2rem;
  }
}

.depoimento .depoimento-title small {
  display: block;
}

.avaliacoes {
  box-shadow: inset 0 1px 0 #eee;
}

.avaliacoes .item-avaliacao + .item-avaliacao {
  margin-top: 1rem;
}

@media (min-width: 577px) {
  .avaliacoes .item-avaliacao + .item-avaliacao {
    margin-top: 2rem;
  }
}

.avaliacoes .avaliacao-autor {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.avaliacoes .avaliacao-estrelas {
  margin-bottom: 0.5rem;
}

.avaliacoes .avaliacao-estrelas .fa {
  font-size: 0.85rem;
}

.review-rating {
  color: #eee;
  display: flex;
  line-height: 1;
  font-size: 1.15rem;
}

.review-rating .fa + .fa {
  padding-left: 0.25rem;
}

.review-rating .fa.-checked {
  color: #fde396;
}

.institucional {
  position: relative;
  overflow: hidden;
}

.institucional.quem-somos .col-bg-image {
  background-image: url(../img/quem-somos-bg.jpg);
}

.institucional.quem-somos .col-bg-image img {
  opacity: 0;
}

.institucional.sac .col-bg-image {
  background-image: url(../img/sac-bg.jpg);
}

.institucional.login .col-bg-image {
  background-image: url(../img/login-bg.jpg);
}

.institucional .col-bg-image {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.institucional .article {
  font-size: 1rem;
  min-height: 66.666678vh;
}

.institucional .article .title {
  font-size: 2rem;
  color: #162882;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  letter-spacing: -0.61803px;
  word-spacing: .25rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

@media (min-width: 993px) {
  .institucional .article .title {
    font-size: 42px;
  }
}

.institucional .article .article-footer {
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-itemPixes: center;
  margin-top: 3rem;
}

.institucional .article .article-footer .btn {
  display: flex;
  flex-flow: row nowrap;
  align-itemPixes: center;
  justify-content: center;
}

.institucional .article .article-footer .btn .fa {
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.institucional .article .article-footer .btn + .btn {
  margin-left: 1.5rem;
}

.institucional .article .article-footer .btn + .btn .fa {
  margin-left: 0.5rem;
  margin-right: 0;
}

.institucional .article .list-buttons {
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-itemPixes: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.institucional .article .list-buttons .btn {
  display: flex;
  flex-flow: row nowrap;
  align-itemPixes: center;
  justify-content: space-between;
  min-width: calc(50% - 0.5rem);
  font-size: 14px;
}

.institucional .article .list-buttons .btn + .btn {
  margin-left: 1rem;
}

.institucional .article .list-buttons .btn .fa {
  font-size: 1.5rem;
}

.institucional .article .list-buttons .btn-login {
  background-color: white;
  color: #aaa;
  font-size: 12px;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.institucional .article .list-buttons .btn-login:hover {
  box-shadow: 0 0 0 1px #ddd, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.institucional .article .list-buttons .btn-login:focus {
  box-shadow: 0 0 0 2px #206cd7, 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.institucional .article .list-buttons .btn-login img {
  max-width: 1.5rem;
  max-height: 1.5rem;
  border-radius: 3px;
}

.institucional .accordion .card {
  padding: 1rem 0;
  background: transparent;
  box-shadow: 0 -1px 0 0 #ddd;
  border-radius: 0;
}

.institucional .accordion .card:last-child {
  border-bottom: 1px solid #ddd;
}

.institucional .accordion .card .card-header {
  padding: 0;
  background: transparent;
}

.institucional .accordion .card .card-body {
  padding: 1rem 0 0 0;
}

.institucional .accordion .card .cart-title {
  font-size: 1rem;
  color: #162882;
  font-weight: bold;
}

.form-login .form-group {
  margin-bottom: 1.5rem;
}

.listagem-videoaulas .card .card-header {
  color: white;
  background-color: #162882;
  border-radius: 5px !important;
}

.listagem-videoaulas .card .cart-title {
  font-size: 1rem;
}

.listagem-videoaulas .listagem-aulas {
  padding: 0;
}

.listagem-videoaulas .listagem-aulas .aula {
  background-color: #f8f8fa;
  display: flex;
  align-itemPixes: center;
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-videoaulas .listagem-aulas .aula {
    padding: 0.5rem 1rem;
  }
}

@media (min-width: 577px) {
  .listagem-videoaulas .listagem-aulas .aula {
    padding: 1rem;
  }
}

.listagem-videoaulas .listagem-aulas .aula img {
  margin-right: 1rem;
}

.listagem-videoaulas .listagem-aulas .aula + .aula {
  border-top: 1px solid #eee;
}

.noticia-section {
  border-top: 1px solid #eee;
}

.noticias .destaques {
  margin-bottom: 3rem;
}

@media (min-width: 0) and (max-width: 576px) {
  .noticias .destaques .noticia {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.noticias .destaques .datetime {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.noticias .destaques .datetime time {
  display: inline-block;
  background-color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  color: #333;
}

.noticias .destaques .title {
  font-weight: bold;
  margin-top: 0;
}

.noticias .destaques .noticia-destaque .media-body {
  padding: 2rem;
  background-image: linear-gradient(180deg, transparent 40%, rgba(0, 0, 0, 0.6));
}

.noticias .destaques .noticia-destaque .title {
  margin-bottom: 0;
}

@media (min-width: 0) and (max-width: 576px) {
  .noticias .destaques .noticia-destaque .title {
    margin-top: 1.25rem;
  }
}

@media (min-width: 577px) {
  .noticias .destaques .noticia-destaque .title {
    margin-top: 1.25rem;
  }
}

.noticias .destaques .noticia-destaque .media:hover .title {
  color: inherit;
  margin-bottom: 1rem;
}

.noticias .destaques .noticia-destaque .media:hover .description {
  display: block;
}

.noticias .destaques .noticia-destaque .description {
  animation: animeStickyHeader 0.618s 0s normal both;
  animation-timing-function: cubic-bezier(0.16, 0.56, 0.17, 0.999);
  display: none;
}

.noticias .destaques .noticia-aside-destaque {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noticias .destaques .noticia-aside-destaque .media-body {
  padding: 1rem;
  background-image: linear-gradient(180deg, transparent 60%, rgba(0, 0, 0, 0.6));
}

.noticias .destaques .noticia-aside-destaque .title {
  font-size: 1rem;
  margin: 0;
}

.noticias .destaques .noticia-aside-destaque .description {
  display: none;
}

.noticias .destaques .noticia-aside-destaque .media:hover .title {
  color: inherit;
}

.noticias .destaques .media {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.noticias .destaques .media .embed-responsive {
  z-index: 0;
  background-color: #232323;
}

.noticias .destaques .media .embed-responsive img {
  opacity: 0.8613;
}

.noticias .destaques .media .media-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.noticia .embed-responsive {
  border-radius: 5px;
  overflow: hidden;
}

.noticia .datetime {
  margin-bottom: 0.25rem;
  line-height: 1;
}

.noticia a {
  text-decoration: none;
}

.noticia a:hover .title {
  color: #206cd7;
}

.noticia a:hover .img-noticia {
  transform: scale(1.05);
}

.noticia.-main .title {
  font-size: 2.5rem;
  margin-top: 0;
  color: #232323;
  font-weight: bold;
  line-height: 1.16381;
  margin-bottom: 2rem;
}

.noticia.-main .article-body {
  margin-top: 2rem;
}

.noticia.-main .article-body p {
  font-size: 1.25rem;
  margin-bottom: 0;
  margin-top: 2rem;
}

.listagem-noticias .noticia-item {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
}

.listagem-noticias .noticia-item:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-noticias .noticia-item + .noticia-item {
    margin-top: 1.5rem;
  }
}

@media (min-width: 577px) {
  .listagem-noticias .noticia-item + .noticia-item {
    margin-top: 2rem;
  }
}

.listagem-noticias .embed-responsive {
  border-radius: 5px;
  overflow: hidden;
}

@media (min-width: 577px) {
  .listagem-noticias .embed-responsive {
    max-width: 33.33334%;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-noticias .media {
    flex-flow: column nowrap;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-noticias .media-body {
    padding-left: 0;
  }
}

@media (min-width: 577px) {
  .listagem-noticias .media-body {
    padding-left: 1.5rem;
  }
}

.listagem-noticias .datetime {
  color: #aaa;
  font-size: small;
  line-height: 1;
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem-noticias .datetime {
    margin-top: 0.5rem;
  }
}

.listagem-noticias .title {
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.1683;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

@media (min-width: 0) and (max-width: 576px) {
  .aside-noticias {
    margin-top: 3rem;
  }
}

.aside-noticias .title-aside {
  text-transform: uppercase;
  font-weight: bold;
  color: #232323;
  margin-bottom: 1.5rem;
}

@media (min-width: 0) and (max-width: 576px) {
  .aside-noticias .title-aside {
    font-size: 1.25rem;
    padding-top: 1rem;
    text-align: center;
  }
}

@media (min-width: 577px) {
  .aside-noticias .title-aside {
    font-size: 1.1rem;
  }
}

.aside-noticias .listagem-aside {
  margin-bottom: 2rem;
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
}

.aside-noticias .listagem-aside:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

.aside-noticias .listagem-aside + .listagem-aside {
  margin-top: 2rem;
}

.aside-noticias .listagem-aside .noticia-item + .noticia-item {
  border-top: 1px solid #eee;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.aside-noticias .listagem-aside .embed-responsive {
  display: none;
}

.aside-noticias .listagem-aside .title {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1683;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

.aside-noticias .listagem-aside .datetime {
  color: #aaa;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.aside-noticias .listagem-aside .description {
  display: none;
}

.listagem-erratas .errata-item + .errata-item {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid #eee;
}

.listagem-erratas .errata {
  align-itemPixes: center;
}

.listagem-erratas .errata .fa {
  font-size: 4rem;
  margin-right: 2rem !important;
}

.listagem-erratas .errata .title {
  font-size: 1.5rem;
  color: #232323;
  margin-bottom: 0;
}

.listagem-erratas .errata .description {
  margin-bottom: 0.5rem;
}

.listagem-erratas .errata .datetime {
  color: #aaa;
  line-height: 1;
}

.listagem-erratas .errata a:hover {
  color: #f86a60;
}

.listagem-erratas .errata a:hover .title {
  color: inherit;
}

.errata-item:hover {
  color: #f86a60;
}

.errata-item:hover .title {
  color: inherit;
}

.errata-item + .errata-item {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #f8f8fa;
}

.errata-item .datetime {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.errata .title {
  font-size: 2rem;
  color: #232323;
  line-height: 1.2;
  margin-top: 0;
  font-weight: bold;
}

.errata .article-body {
  margin-top: 2rem;
}

.errata .article-body p {
  font-size: 1.15rem;
  margin-bottom: 0;
  margin-top: 2rem;
}

@media (min-width: 0) and (max-width: 576px) {
  .planos .col-12 {
    padding: 1rem;
  }
}

.planos .row {
  justify-content: center;
}

.planos .plano {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
}

.planos .plano:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

.planos .plano.-destaque {
  border: 2px solid #fd7a0f;
}

.planos .plano:hover {
  transform: translateY(-3px);
}

.planos .plano .plano-header {
  text-align: center;
}

.planos .plano .title {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  letter-spacing: -0.61803px;
  word-spacing: .25rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 3rem;
  margin-top: 1rem;
  color: #232323;
}

.planos .plano .description {
  margin: 0;
  line-height: 1;
}

.planos .plano .price {
  margin-top: 0;
  font-size: 1rem;
  line-height: 1;
}

.planos .plano .price strong {
  font-size: 3.5rem;
}

.planos .plano .plano-body {
  margin: 2rem 0;
}

.planos .plano .feature {
  display: flex;
  align-itemPixes: center;
}

.planos .plano .feature + .feature {
  margin-top: 0.5rem;
}

.planos .plano .feature.-disabled {
  color: #aaa;
  text-decoration: line-through;
}

.planos .plano .feature.-disabled .fa {
  color: inherit;
  text-decoration: none;
}

.planos .plano .feature.-disabled .fa::before {
  content: "\f00d";
}

.planos .plano .feature .fa {
  color: #0db870;
  width: 2rem;
}

#html .btn-planos {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1020;
  color: white;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 15px 0 rgba(0, 0, 0, 0.16), 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

@media (min-width: 0) and (max-width: 576px) {
  #html .btn-planos {
    margin: 1rem 6rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  #html .btn-planos {
    margin: 1.5rem 7rem;
  }
}

@media (min-width: 993px) {
  #html .btn-planos {
    margin: 2rem 7rem;
  }
}

#html .btn-planos:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
}

#html .btn-planos:focus {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08), 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(0, 0, 0, 0.08);
}

#html .btn-planos:hover {
  transform: translateY(-3px);
}

.questions-filter {
  background-color: #f8f8fa;
  padding-top: 2rem !important;
  padding-bottom: 1rem !important;
}

.questions-filter .form-row {
  align-itemPixes: center;
  margin: 0;
}

@media (max-width: 575.98px) {
  .questions-filter .form-row {
    display: block;
  }
  .questions-filter .form-row .form-group {
    padding: 0;
  }
}

.questions-filter .form-row + .form-row {
  margin-top: 1.5rem;
}

.questions-filter .title {
  font-size: 1rem;
  font-weight: bold;
  color: #232323;
  margin: 0 1rem 0 0;
}

.questions-filter label {
  margin: 0;
}

.questions-filter .btn.-small {
  border-radius: 2rem;
  margin-left: 1rem;
  background-color: #eee;
}

.questions-filter .btn.-small:hover {
  background-color: #fd7a0f;
  color: white;
}

@media (min-width: 993px) {
  .questions-filter .form-group {
    margin-bottom: 0;
  }
}

.questions-filter .btn-submit {
  align-self: flex-end;
}

.questions-filter .form-check-input {
  height: auto;
  line-height: 1;
}

.questions-filter .form-check-label {
  font-size: small;
}

.questions-filter input[name="questionVariation"]:checked + .btn {
  background-color: #fd7a0f;
  color: white;
}

.list-questions .section {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 0) and (max-width: 576px) {
  .list-questions .section {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .list-questions .section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 993px) {
  .list-questions .section {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

#html .question.-answered .btn,
#html .question.-answered label,
#html .question.-answered .answer-item {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}

#html .question.-answered .btn {
  color: #aaa;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #aaa;
}

#html .question.-right-answer .question-id {
  background-color: #0db870;
  color: white;
}

#html .question.-right-answer input:checked ~ .desc {
  color: #0db870;
}

#html .question.-right-answer input:checked + .option {
  background-color: #0db870;
}

#html .question.-right-answer .alert-right-answer {
  display: flex;
}

#html .question.-wrong-answer .question-id {
  background-color: #f12b2b;
  color: white;
}

#html .question.-wrong-answer input:checked ~ .desc {
  color: #f12b2b;
}

#html .question.-wrong-answer input:checked + .option {
  background-color: #f12b2b;
}

#html .question.-wrong-answer .alert-wrong-answer {
  display: flex;
}

#html .question.-wrong-answer .alert-answer {
  display: flex;
}

.question-item {
  padding: 0;
}

.question {
  box-shadow: 0 0 0 2px #eee;
  border-radius: 5px;
  overflow: hidden;
}

.question.-check .answer-item label {
  align-itemPixes: center;
}

.question .question-breadcrumb {
  line-height: 1;
  border-bottom: 2px solid #eee;
}

@media (min-width: 768px) {
  .question .question-breadcrumb {
    display: flex;
  }
}

.question .question-breadcrumb.-title {
  background-color: #eee;
  box-shadow: none;
  overflow: hidden;
  border: 0 none;
}

.question .question-breadcrumb .question-id {
  background-color: #eeeeee;
  color: #162882;
  box-shadow: none !important;
  font-weight: bold;
}

@media (min-width: 0) and (max-width: 576px) {
  .question .question-breadcrumb li {
    padding: 0.5rem;
  }
}

@media (min-width: 768px) {
  .question .question-breadcrumb li {
    padding: 0.5rem 1rem;
  }
}

.question .question-breadcrumb li.li-icon {
  padding-left: 0;
  padding-right: 0;
}

.question .question-breadcrumb li.li-icon .fa {
  font-size: 1.15rem;
  line-height: 0.75;
  text-shadow: 0 0 0;
}

@media (min-width: 0) and (max-width: 576px) {
  .question .question-body {
    padding: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .question .question-body {
    padding: 1.5rem;
  }
}

@media (min-width: 993px) {
  .question .question-body {
    padding: 2rem;
  }
}

.question .question-body .title {
  font-size: inherit;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: unset;
}

.question .question-body .subtitle {
  font-size: inherit;
}

.question .question-body .desc {
  font-weight: normal;
}

.question .answer-item {
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

.question .answer-item:hover {
  text-shadow: 0 0 0;
}

.question .answer-item + .answer-item {
  margin-top: 1.5rem;
}

.question .answer-item label {
  display: flex;
  margin: 0;
  cursor: pointer;
}

.question .answer-item input:checked ~ .desc {
  font-weight: 600;
  text-shadow: 0 0 0;
  color: #232323;
}

.question .answer-item input:checked + .option {
  color: white;
  background-color: #fd7a0f;
  font-weight: 300;
}

.question .answer-item .option {
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  background-color: #eee;
  margin-right: 1.5rem;
  border-radius: 2rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.question .answer-item p {
  margin: 0;
}

.question .question-actions {
  font-weight: bold;
  line-height: 1;
  padding: 0 2rem 2rem 2rem;
}

@media (min-width: 768px) {
  .question .question-actions {
    display: flex;
    align-itemPixes: center;
  }
}

.question .question-actions li {
  display: flex;
  align-itemPixes: center;
}

@media (min-width: 0) and (max-width: 576px) {
  .question .question-actions li + li {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) {
  .question .question-actions li + li {
    margin-left: 1.5rem;
  }
}

.question .question-actions li .fa {
  font-size: 1.5rem;
  line-height: 0.8;
  margin-right: 0.5rem;
}

.question .question-actions .alert-right-answer {
  color: #0db870;
  display: none;
}

.question .question-actions .alert-wrong-answer {
  color: #f12b2b;
  display: none;
}

.question .question-actions .alert-answer {
  display: none;
}

.question .question-actions .alert-answer strong {
  font-size: 1rem;
  margin-left: 2px;
  font-family: 'Montserrat', sans-serif;
}

.question .question-footer {
  padding: 1rem 2rem;
  background-color: #f8f8fa;
}

.question .question-footer .nav {
  justify-content: space-between;
  align-itemPixes: center;
}

.question .question-footer .nav .nav-link {
  border-radius: 2rem;
  background: transparent;
  font-weight: bold;
}

.question .question-footer .nav .nav-link:hover {
  box-shadow: inset 0 0 0 2px #206cd7;
  color: #206cd7;
}

.question .question-footer .nav .nav-link.active, .question .question-footer .nav .nav-link:active {
  color: white;
  background-color: #206cd7;
}

.question .question-footer .tab-pane {
  padding: 2rem 0;
}

.question .question-footer .tab-pane .tab-pane-header {
  margin-bottom: 2rem;
}

.question .question-footer .tab-pane .tab-pane-header .title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.question .question-footer .tab-pane .tab-pane-header .subtitle {
  font-size: inherit;
  line-height: 1;
}

.question .question-footer .tab-pane .item-avaliacao + .item-avaliacao {
  margin-top: 2rem;
}

.question .question-footer .tab-pane .avaliacao {
  display: flex;
  flex-flow: row nowrap;
}

.question .question-footer .tab-pane .avaliacao .avaliacao-header {
  padding: 0;
  margin-right: 1rem;
  text-align: center;
  flex: 0 0 16.6666%;
  max-width: 16.6666%;
}

.question .question-footer .tab-pane .avaliacao .avaliacao-header .title {
  font-size: inherit;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.question .question-footer .tab-pane .avaliacao .avaliacao-article {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 8rem;
  border-radius: 5px;
  line-height: 1;
  display: block;
}

.question .question-footer .tab-pane .avaliacao .avaliacao-article:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

@media (min-width: 768px) {
  .question .question-footer .tab-pane .avaliacao .avaliacao-article.-large {
    padding: 2rem;
  }
}

.question .question-footer .tab-pane .avaliacao .datetime {
  color: #aaa;
  font-size: small;
  line-height: 1.2;
}

.adquirir-plano {
  text-align: center;
  color: #162882;
}

.adquirir-plano .container {
  position: relative;
}

.adquirir-plano img {
  opacity: 0.166678;
}

.adquirir-plano .title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
}

.page.-home .nav-item.-home {
  color: #162882;
}

.page.-home .nav-item.-home a::before {
  filter: grayscale(0) !important;
}

.home-features {
  padding-bottom: 0;
  background-color: #f8f8fa;
}

@media (max-width: 575.98px) {
  .home-features .row {
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .home-features .row {
    justify-content: space-between;
  }
}

.home-features .home-feature {
  line-height: 1.2;
}

@media (max-width: 575.98px) {
  .home-features .home-feature {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.home-features .home-feature figure {
  display: flex;
  flex-flow: wrap row;
  align-itemPixes: center;
}

.home-features .home-feature figure img {
  margin-right: 1rem;
}

.home-features .home-feature figure strong {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.page.-institucional {
  background-image: url(../img/svg-institucional-bg-branco.svg);
  background-position: top -2px left;
  background-repeat: no-repeat;
  overflow: hidden;
}

@media (min-width: 993px) {
  .page.-institucional::after {
    display: block;
    content: url(../img/svg-institucional-bg-azul.svg);
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    z-index: 2;
    opacity: 0.05;
  }
}

.page.-institucional .main-header,
.page.-institucional .page-title,
.page.-institucional .header-bottom-bar {
  background-color: transparent;
}

.page.-institucional .main-content {
  background-image: none;
}

.page.-institucional .main-footer {
  box-shadow: none;
  z-index: 3;
  position: relative;
}

.page.-institucional .nav-item.-institucional {
  color: #162882;
}

.page.-institucional .nav-item.-institucional .nav-link {
  border-top-color: inherit;
}

.page.-institucional .page-title {
  padding: 0;
  margin: 0;
}

.page.-institucional .page-title .breadcrumb {
  margin: 0;
  padding: 0.5rem 0;
  font-size: 12px;
}

.page.-institucional .page-title .breadcrumb span {
  color: #162882;
}

.listagem-provas .prova-item:nth-child(even) .prova {
  background-color: #f8f8fa;
}

.listagem-provas .prova {
  display: flex;
  align-itemPixes: center;
  justify-content: space-between;
  padding: 2rem;
}

.listagem-provas .prova:hover .fa,
.listagem-provas .prova:hover .title {
  color: #80acff;
}

.listagem-provas .prova .title-prova {
  font-size: small;
  margin-bottom: 0;
  line-height: 1;
}

.listagem-provas .prova .title {
  font-weight: bold;
  color: #232323;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.listagem-provas .prova .datetime {
  line-height: 1;
}

.listagem-provas .prova .fa {
  font-size: 2rem;
  color: #162882;
}

.listagem-provas .prova .actions {
  display: flex;
  align-itemPixes: center;
}

.listagem-provas .prova .actions .dropdown {
  margin-left: 1rem;
}

.listagem-provas .prova .actions .dropdown .dropdown-toggle::after {
  display: none;
}

.prova ol {
  counter-reset: section;
}

.prova ol li {
  position: relative;
}

.prova ol li::before {
  counter-increment: section;
  content: counters(section, ".") " ";
  display: inline-block;
  margin-right: 0.25rem;
}

.prova .title-questoes {
  background-color: #f8f8fa;
  box-shadow: 0 0 0 2px #f8f8fa;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  padding: 1rem;
}

.prova .listagem-questoes-prova {
  box-shadow: 0 0 0 2px #f8f8fa;
  border-radius: 5px;
  overflow: hidden;
}

.prova .listagem-questoes-prova > li + li {
  box-shadow: 0 -2px 0 0 #f8f8fa;
}

.prova .questao-prova::before {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  line-height: 1.2;
  font-size: 1rem;
  font-weight: bold;
}

.prova .card-header {
  background-color: transparent;
  padding: 1rem 2.25rem;
  line-height: 1;
  cursor: pointer;
}

.prova .card-title {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.prova .card-title strong {
  color: #fd7a0f;
}

.prova .card-body {
  padding: 0 2rem 1rem 2rem;
}

.prova .card-body .questao-prova-materia {
  padding-right: 6rem;
  line-height: 1.2;
}

.prova .card-body .questao-prova-materia + .questao-prova-materia {
  margin-top: 1rem;
}

.prova .card-body .questao-prova-materia .qtd {
  color: #fd7a0f;
  position: absolute;
  right: 0;
  top: 0;
}

.account .navbar-nav {
  align-itemPixes: center;
}

.account .main-nav-box-card {
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #eee;
}

.account hr.hr-account {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: #eee;
}

.account .title-message {
  font-weight: bold;
  font-size: 1rem;
}

.box-card.-account {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 8rem;
  border-radius: 5px;
  line-height: 1;
  height: 100%;
}

.box-card.-account:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

@media (min-width: 768px) {
  .box-card.-account.-large {
    padding: 2rem;
  }
}

@media (min-width: 0) and (max-width: 992px) {
  .box-card.-account {
    padding: 2rem 1rem;
  }
}

@media (min-width: 993px) {
  .box-card.-account {
    padding: 3rem 2rem;
  }
}

.box-card.-account .navbar {
  max-width: 100%;
}

.box-card.-account .navbar-brand {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: #232323;
  padding: 0;
  margin: 0;
}

@media (min-width: 577px) {
  .box-card.-account .navbar-brand {
    margin: 0 2rem;
  }
}

@media (min-width: 0) and (max-width: 576px) {
  .box-card.-account .nav-item + .nav-item {
    margin-top: 0.5rem;
  }
}

@media (min-width: 577px) {
  .box-card.-account .nav-item + .nav-item {
    margin-left: 1rem;
  }
}

.box-card.-account .nav-link {
  line-height: 2rem;
  padding: 0 1rem;
  border-radius: 2rem;
  background-color: white;
  font-weight: bold;
  box-shadow: inset 0 0 0 2px #eee;
}

.box-card.-account .nav-link:hover, .box-card.-account .nav-link:active, .box-card.-account .nav-link.active {
  background-color: #206cd7;
  box-shadow: inset 0 0 0 2px #206cd7;
  color: white;
}

@media (min-width: 0) and (max-width: 576px) {
  .dashboard-features li + li {
    margin-top: 1rem;
  }
}

.dashboard-features .dashboard-feature {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 8rem;
  border-radius: 5px;
  line-height: 1;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid transparent;
}

.dashboard-features .dashboard-feature:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

@media (min-width: 768px) {
  .dashboard-features .dashboard-feature.-large {
    padding: 2rem;
  }
}

.dashboard-features .dashboard-feature.-questoes {
  color: #fd7a0f;
  border-bottom-color: inherit;
}

.dashboard-features .dashboard-feature.-respostas-corretas {
  color: #0db870;
  border-bottom-color: inherit;
}

.dashboard-features .dashboard-feature.-respostas-erradas {
  color: #f12b2b;
  border-bottom-color: inherit;
}

.dashboard-features .dashboard-feature.-list-item {
  color: inherit;
  border-bottom-color: inherit;
}

.dashboard-features .dashboard-feature.-list-item .title {
  margin-bottom: 1rem;
}

.dashboard-features .dashboard-feature .qtd {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.dashboard-features .dashboard-feature .title {
  color: #aaa;
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0;
}

.dashboard-features .dashboard-feature .info {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  color: #aaa;
  font-size: 1.25rem;
}

.grafico-wrapper {
  position: relative;
  display: flex;
}

.grafico-wrapper::before {
  content: "";
  padding-top: 100%;
  display: block;
  float: left;
}

.grafico-wrapper .grafico {
  height: 100%;
}

.section-hero.-carrinho {
  display: flex;
  justify-content: space-between;
  align-itemPixes: center;
}

.section-hero.-carrinho .btns .btn + .btn {
  margin-left: 1rem;
}

.listagem.-itens-pedido td {
  text-align: center;
}

.listagem.-itens-pedido td:first-child {
  width: 60%;
  text-align: left;
}

.listagem.-itens-pedido td:last-child {
  text-align: inherit;
  white-space: nowrap;
}

.pagamento-main .box-card + .box-card {
  margin-top: 2rem;
}

.pagamento-aside .box-card + .box-card {
  margin-top: 2rem;
}

.form.form-pagamento-cartao label {
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
}

.form.form-pagamento-cartao label span {
  display: inline-block;
  line-height: 1;
}

.form.form-pagamento-cartao label span + .form-control {
  margin-top: 0.5rem;
}

.info-tooltip {
  color: #aaa;
  font-size: 1rem;
  vertical-align: middle;
  line-height: 0.85 !important;
}

.info-tooltip .fa {
  line-height: 0.85;
}

.page.-gateway {
  background: #f8f8fa;
}

.page.-gateway .main-content {
  background: none;
}

.page.-gateway .main-footer {
  background-color: white;
  box-shadow: inset 0 2px 0 #eee;
}

.page.-gateway .header-top-bar,
.page.-gateway .btn-wishlist,
.page.-gateway .btn-cart,
.page.-gateway .header-bottom-bar {
  display: none !important;
}

.page.-gateway .btn-planos,
.page.-gateway .float-button-whatsapp,
.page.-gateway .institucional-bar {
  display: none !important;
}

.page.-gateway .header-middle-bar {
  padding: 1rem 0;
}

.page.-gateway .main-content .section {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 0) and (max-width: 576px) {
  .page.-gateway .main-content .section {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .page.-gateway .main-content .section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 993px) {
  .page.-gateway .main-content .section {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.page.-gateway .box-card {
  border-radius: 5px;
  padding: 2rem;
  overflow: hidden;
}

.page.-gateway .box-card.-carrinho {
  padding: 0;
}

.page.-gateway .box-card.-carrinho .row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.page.-gateway .box-card .box {
  padding: 2rem;
}

.page.-gateway .box-card .box.-total {
  background: #ededee;
  color: inherit;
  text-align: right;
}

.page.-gateway .box-card .box.-total .title {
  color: inherit;
}

.page.-gateway .box-card .box.-frete {
  text-align: right;
  align-itemPixes: flex-end;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
}

.page.-gateway .box-card .box.-frete .frete-title {
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

.page.-gateway .box-card .box.-frete .frete-type {
  font-size: 1rem;
  line-height: 1;
}

.page.-gateway .box-card .box.-frete .frete-type strong {
  font-size: 225%;
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  line-height: inherit;
}

.page.-gateway .box-card .box.-frete .datetime {
  color: #aaa;
  font-size: small;
  line-height: 1;
}

.page.-gateway .box-card .title {
  font-size: 1rem;
  text-transform: uppercase;
  color: #232323;
  line-height: 1;
  margin-bottom: 1.5rem;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  letter-spacing: -0.61803px;
  word-spacing: .25rem;
}

.page.-gateway .price {
  font-size: 1rem;
  line-height: 1.1;
}

.page.-gateway .price.-subtotal {
  opacity: 0.8;
}

.page.-gateway .price.-total {
  font-size: 2rem;
  color: #232323;
}

.page.-gateway .section-hero {
  position: relative;
  text-align: left;
}

.page.-gateway .form .form-control {
  padding: 0 1.15rem;
  line-height: 2.5rem;
  height: 2.5rem;
}

.page.-gateway .form textarea.form-control {
  height: auto;
  line-height: 1.61803;
}

.page.-gateway .form .form-group .form-group [class*="col-"] {
  padding: 0;
}

.page.-gateway .box-card.-total {
  text-align: right;
}

.page.-gateway .box-card.-frete-desconto {
  flex-flow: row wrap;
  padding: 0;
}

.page.-gateway .box-card.-frete-desconto .col-md-6 {
  padding: 0;
}

.page.-account.-dashboard .nav-item.-dashboard {
  color: #206cd7;
}

.page.-account.-dashboard .nav-item.-dashboard .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-editar .nav-item.-editar {
  color: #206cd7;
}

.page.-account.-editar .nav-item.-editar .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-alterar-senha .nav-item.-alterar-senha {
  color: #206cd7;
}

.page.-account.-alterar-senha .nav-item.-alterar-senha .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-boleto-gerar .nav-item.-boleto-gerar {
  color: #206cd7;
}

.page.-account.-boleto-gerar .nav-item.-boleto-gerar .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-assinaturas .nav-item.-assinaturas {
  color: #206cd7;
}

.page.-account.-assinaturas .nav-item.-assinaturas .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-meu-desempenho .nav-item.-meu-desempenho {
  color: #206cd7;
}

.page.-account.-meu-desempenho .nav-item.-meu-desempenho .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-minhas-estatisticas .nav-item.-minhas-estatisticas {
  color: #206cd7;
}

.page.-account.-minhas-estatisticas .nav-item.-minhas-estatisticas .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-minhas-questoes .nav-item.-minhas-questoes {
  color: #206cd7;
}

.page.-account.-minhas-questoes .nav-item.-minhas-questoes .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-meus-simulados .nav-item.-meus-simulados {
  color: #206cd7;
}

.page.-account.-meus-simulados .nav-item.-meus-simulados .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-meus-cadernos .nav-item.-meus-cadernos {
  color: #206cd7;
}

.page.-account.-meus-cadernos .nav-item.-meus-cadernos .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-meus-cursos .nav-item.-meus-cursos {
  color: #206cd7;
}

.page.-account.-meus-cursos .nav-item.-meus-cursos .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-meus-conteudos .nav-item.-meus-conteudos {
  color: #206cd7;
}

.page.-account.-meus-conteudos .nav-item.-meus-conteudos .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-pagamentos .nav-item.-pagamentos {
  color: #206cd7;
}

.page.-account.-pagamentos .nav-item.-pagamentos .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-editar .nav-item.-editar {
  color: #206cd7;
}

.page.-account.-editar .nav-item.-editar .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-alterar-senha .nav-item.-alterar-senha {
  color: #206cd7;
}

.page.-account.-alterar-senha .nav-item.-alterar-senha .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-boleto-gerar .nav-item.-boleto-gerar {
  color: #206cd7;
}

.page.-account.-boleto-gerar .nav-item.-boleto-gerar .nav-link {
  border-bottom-color: inherit;
}

.page.-account.-assinaturas .nav-item.-assinaturas {
  color: #206cd7;
}

.page.-account.-assinaturas .nav-item.-assinaturas .nav-link {
  border-bottom-color: inherit;
}

.account-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: auto;
  background-color: white;
  z-index: 987658;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 320px;
  padding-bottom: 4rem;
  overflow-y: auto;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateX(-105%);
  transition: all 0.5s cubic-bezier(0.16, 0.56, 0.17, 0.999);
}

.account-menu.-show {
  transform: translateX(0);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.05), 0 9px 46px 8px rgba(0, 0, 0, 0.05), 0 11px 15px -7px rgba(0, 0, 0, 0.08);
}

.account-menu .account-menu-header .js-btn-menu-account {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  height: auto;
  line-height: 1;
  font-size: 1.25rem;
  color: #999;
  padding: 0.5rem 0.75rem;
}

.account-menu .account-navigation .nav-item + .nav-item {
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 993px) {
  .account-menu .account-navigation .nav-item {
    padding-left: 1rem;
  }
}

.account-menu .account-navigation .nav-item:hover, .account-menu .account-navigation .nav-item:active, .account-menu .account-navigation .nav-item.active {
  background-color: #206cd7;
  color: #f8f8fa;
}

.account-menu .account-navigation .nav-item .nav-link {
  display: flex;
  align-itemPixes: center;
  font-weight: bold;
}

.account-menu .account-navigation .nav-item .fa {
  font-size: 1.25rem;
  margin-right: 1rem;
  line-height: 1;
}

@media (min-width: 0) and (max-width: 576px) {
  .account-menu .user {
    padding: 1.5rem;
  }
}

@media (min-width: 577px) {
  .account-menu .user {
    padding: 2rem;
  }
}

.user .user-photo {
  position: relative;
  max-width: 128px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  background-image: url(../img/svg-user-photo.svg);
}

.user .user-photo::before {
  display: block;
  padding-top: 100%;
  content: " ";
}

.user .user-photo img {
  opacity: 0;
}

.user .user-name {
  font-size: 1.25rem;
  color: #232323;
  line-height: 1;
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

.account-dl dt {
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
  color: #aaa;
  font-weight: normal;
}

.account-dl dd {
  font-weight: bold;
}

.account-dl dd + dt {
  margin-top: 1rem;
}

.user-card .account-dl {
  margin-top: 2rem;
}

.form.-account .form-group {
  margin-bottom: 1.5rem;
}

.form.-account .form-control {
  box-shadow: 0 2px 0px 0px #d9dde2;
  border-radius: 0;
  padding: 0;
}

.form.-account .form-control:hover {
  box-shadow: 0 2px 0px 0px #99a;
}

.form.-account .form-control:focus {
  box-shadow: 0 2px 0px 0px #206cd7;
}

.form.-account .caption {
  font-size: 1rem;
}

.form-actions {
  text-align: right;
  margin-top: 1rem;
}

.form-actions .btn + .btn {
  margin-left: 2rem;
}

.account-aside .title {
  font-size: 1.5rem;
  font-weight: bold;
}

.listagem-assinaturas .assinatura {
  padding: 0;
}

.listagem-assinaturas .assinatura + .assinatura {
  margin-top: 2rem;
}

.listagem-assinaturas .assinatura.-current .assinatura-aside {
  background-color: #206cd7;
  color: #f8f8fa;
}

.listagem-assinaturas .assinatura .account-dl dt {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.4);
}

.listagem-assinaturas .assinatura .assinatura-content {
  padding: 2rem;
}

@media (min-width: 577px) {
  .listagem-assinaturas .assinatura .assinatura-content {
    padding: 3rem;
    display: flex;
    align-itemPixes: center;
    justify-content: space-between;
  }
}

.listagem-assinaturas .assinatura .assinatura-content .title {
  font-size: 1rem;
  line-height: 1;
}

.listagem-assinaturas .assinatura .assinatura-content .title strong {
  font-size: 300%;
  display: block;
  text-transform: uppercase;
}

.listagem-assinaturas .assinatura .assinatura-content .title .badge {
  vertical-align: top;
}

.listagem-assinaturas .assinatura .assinatura-content .price {
  font-size: 1rem;
  line-height: 1;
}

.listagem-assinaturas .assinatura .assinatura-content .price span {
  font-size: 300%;
  font-weight: bold;
}

.listagem-assinaturas .assinatura .assinatura-aside {
  padding: 2rem;
  background: #aaa;
  color: #f8f8fa;
}

.listagem-assinaturas .assinatura .assinatura-aside .title {
  font-size: 1.5rem;
  font-weight: bold;
}

.listagem.-estatisticas {
  text-align: center;
}

.listagem td {
  vertical-align: middle;
  padding: 0.5rem;
}

.listagem td:first-child {
  width: 40%;
  text-align: left;
  border-radius: 5px 0 0 5px;
}

.listagem td:last-child {
  border-radius: 0 5px 5px 0;
}

.listagem thead td {
  font-weight: bold;
  font-size: 12px;
}

.listagem tbody td:first-child {
  padding-left: 1rem;
}

.listagem tbody td:last-child {
  text-align: right;
}

.listagem tbody td .btn {
  line-height: 1;
  height: auto;
  padding: 0.5rem 0.75rem;
}

.listagem.-simulados .simulado-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-itemPixes: center;
  padding: 2rem 1rem;
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem.-simulados .simulado-item {
    align-itemPixes: flex-start;
  }
}

.listagem.-simulados .simulado-item:nth-of-type(odd) {
  border-radius: 5px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);
}

.listagem.-simulados .simulado-item .si-content {
  display: flex;
  align-itemPixes: center;
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem.-simulados .simulado-item .si-content {
    flex-flow: column nowrap;
    align-itemPixes: flex-start;
  }
}

.listagem.-simulados .simulado-item .si-content.-left {
  flex-flow: column nowrap;
  align-itemPixes: flex-start;
}

.listagem.-simulados .simulado-item .si-content.-left .badge {
  border-radius: 5px;
  padding: .25rem 0.5rem;
  color: white;
  background: #aaa;
}

.listagem.-simulados .simulado-item .si-content.-right {
  font-weight: 600;
}

.listagem.-simulados .simulado-item .si-content.-right .col {
  white-space: nowrap;
}

@media (min-width: 0) and (max-width: 576px) {
  .listagem.-simulados .simulado-item .si-content.-right .col {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.listagem.-simulados .simulado-item .si-content.-right .col.-spacing {
  padding: 0;
  background-color: #aaa;
  height: 1px;
  max-height: 1px;
  margin: 0.5rem 0;
}

@media (min-width: 577px) {
  .listagem.-simulados .simulado-item .si-content.-right .col.-spacing {
    width: 1px;
    height: 1rem;
    max-height: none;
    margin: auto 0.5rem;
  }
}

.listagem.-simulados .simulado-item .si-content.-right .col.-status {
  color: #aaa;
}

.listagem.-simulados .simulado-item .si-content.-right .col.-status.-false {
  color: #f12b2b;
}

.listagem.-simulados .simulado-item .si-content.-right .col.-status.-true {
  color: #0db870;
}

@media (max-width: 576px) {
  .listagem.-simulados .simulado-item .si-content.-right > .btn {
    border-radius: 2rem;
    box-shadow: inset 0 0 0 2px #206cd7;
  }
}

.listagem.-simulados .simulado-item .si-content.-right > .btn:hover {
  border-radius: 2rem;
  box-shadow: inset 0 0 0 2px #206cd7;
}

.listagem.-simulados .simulado-item .si-content.-right > .btn:focus, .listagem.-simulados .simulado-item .si-content.-right > .btn:active, .listagem.-simulados .simulado-item .si-content.-right > .btn.active {
  border-radius: 2rem;
  background-color: #206cd7;
  color: white;
}

.listagem.-simulados .simulado-item .title {
  font-size: 1rem;
  margin: .5rem 0;
}

.listagem.-simulados .simulado-item .datetime {
  color: #aaa;
  font-size: small;
  line-height: 1;
}

.listagem.-simulados .simulado-item .btn-editar,
.listagem.-simulados .simulado-item .btn-tronar-privado,
.listagem.-simulados .simulado-item .btn-remove {
  padding: 0;
  height: auto;
  line-height: 0.8;
  background: transparent;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  cursor: pointer;
}

.listagem.-simulados .simulado-item .btn-editar:hover,
.listagem.-simulados .simulado-item .btn-tronar-privado:hover,
.listagem.-simulados .simulado-item .btn-remove:hover {
  transform: translateY(-2px);
}

.listagem.-simulados .simulado-item .btn-editar .fa,
.listagem.-simulados .simulado-item .btn-tronar-privado .fa,
.listagem.-simulados .simulado-item .btn-remove .fa {
  line-height: inherit;
  font-size: 1.5rem;
}

.listagem.-simulados .simulado-item .btn-remove {
  color: #f12b2b;
}

.listagem.-simulados .simulado-item .btn-editar {
  color: #206cd7;
}

.listagem.-simulados .simulado-item .btn-tronar-privado {
  color: #206cd7;
}

.listagem-cursos-account > .col {
  padding: 1rem;
}

.listagem-cursos-account .curso-account {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 8rem;
  border-radius: 5px;
  line-height: 1;
  padding: 0;
  height: 100%;
  justify-content: space-between;
}

.listagem-cursos-account .curso-account:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

@media (min-width: 768px) {
  .listagem-cursos-account .curso-account.-large {
    padding: 2rem;
  }
}

.listagem-cursos-account .curso-account .desc {
  padding: 2rem 1rem;
}

.listagem-cursos-account .curso-account .desc .subtitle {
  font-size: 12px;
  color: #aaa;
  text-transform: uppercase;
  line-height: 1.61803;
  font-weight: 600;
  display: block;
  margin-bottom: 0.25rem;
}

.listagem-cursos-account .curso-account .desc .title {
  font-size: 1.25rem;
  font-weight: bold;
}

.listagem-cursos-account .curso-account .curso-footer {
  padding: 1rem;
  border-top: 1px solid #eee;
  color: #aaa;
  font-size: 13px;
}

.listagem-cursos-account .curso-account .curso-footer p {
  line-height: 1;
}

.listagem.-pagamentos td:first-child {
  width: auto;
}

.listagem.-pagamentos td:last-child {
  text-align: right;
}

.carrinho-breadcrumb {
  padding: 2rem 0 0 0;
  margin: 0;
}

.carrinho-breadcrumb .item {
  display: flex;
  flex-wrap: wrap;
  align-itemPixes: center;
  justify-content: center;
  color: #aaa;
}

.carrinho-breadcrumb .item.-active {
  color: #0db870;
}

.carrinho-breadcrumb .item.-active .fa,
.carrinho-breadcrumb .item.-active .title {
  color: inherit;
}

.carrinho-breadcrumb .item.-current .fa {
  color: white;
}

.carrinho-breadcrumb .item.-current .icon-holder {
  background: #aaa;
}

.carrinho-breadcrumb .item .icon-holder {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 3rem;
  text-align: center;
  display: block;
  float: left;
  margin-right: 0.5rem;
}

.carrinho-breadcrumb .item .fa {
  line-height: inherit;
  font-size: 1.25rem;
}

.carrinho-breadcrumb .item .title {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  letter-spacing: -0.61803px;
  word-spacing: .25rem;
  font-size: inherit;
  font-weight: bold;
  line-height: 1.1;
  color: inherit;
}

.carrinho-vazio {
  text-align: center;
}

.carrinho-vazio .img {
  opacity: 0.584687;
}

@media (min-width: 0) and (max-width: 576px) {
  .carrinho-vazio .img {
    width: 200px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .carrinho-vazio .img {
    width: 256px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .carrinho-vazio .img {
    width: 412px;
  }
}

@media (min-width: 993px) {
  .carrinho-vazio .img {
    width: 412px;
  }
}

.carrinho-vazio .title {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  letter-spacing: -0.61803px;
  word-spacing: .25rem;
}

@media (min-width: 0) and (max-width: 768px) {
  .carrinho-vazio .title {
    font-size: 1.5rem;
  }
}

@media (min-width: 993px) {
  .carrinho-vazio .title {
    font-size: 2rem;
  }
}

.table-listagem {
  text-align: center;
}

.table-listagem td {
  vertical-align: middle;
  white-space: nowrap;
}

.table-listagem thead td {
  font-weight: bold;
  padding: 1rem;
}

.table-listagem thead td:first-child {
  border-radius: 5px 0 0 5px;
  text-align: left;
}

.table-listagem tbody td {
  padding: 2rem;
}

.table-listagem tbody td:first-child {
  border-radius: 5px 0 0 5px;
  text-align: left;
  font-weight: 600;
}

.table-listagem tbody td:last-child {
  border-radius: 0 5px 5px 0;
  text-align: right;
}

.table-listagem.-carrinho tbody td:first-child {
  width: 60%;
}

.table-listagem.-carrinho tbody td:last-child {
  width: 10%;
}

.table-listagem.-carrinho .input-number {
  box-shadow: 0 0 0 2px #d9dde2;
  padding: 0;
  display: inline-block;
  text-align: center;
  width: 3rem;
  background: transparent;
  height: auto;
  line-height: 1.5rem;
}

.table-listagem.-carrinho .input-number:hover {
  box-shadow: 0 0 0 2px #99a;
}

.table-listagem.-carrinho .input-number:focus {
  box-shadow: 0 0 0 2px #206cd7;
}

.page.-cursos-online .nav-item.-cursos-online {
  color: #0fb970;
}

.page.-cursos-online .nav-item.-cursos-online .nav-link {
  border-top-color: inherit;
}

.page.-cursos-online .breadcrumb span {
  color: #0fb970;
}

.page.-apostilas .nav-item.-apostilas {
  color: #fd8f36;
}

.page.-apostilas .nav-item.-apostilas .nav-link {
  border-top-color: inherit;
}

.page.-apostilas .breadcrumb span {
  color: #fd8f36;
}

.page.-livros .nav-item.-livros {
  color: #d667c8;
}

.page.-livros .nav-item.-livros .nav-link {
  border-top-color: inherit;
}

.page.-livros .breadcrumb span {
  color: #d667c8;
}

.page.-questoes-de-concursos .nav-item.-questoes-de-concursos {
  color: #ffc965;
}

.page.-questoes-de-concursos .nav-item.-questoes-de-concursos .nav-link {
  border-top-color: inherit;
}

.page.-questoes-de-concursos .breadcrumb span {
  color: #ffc965;
}

.page.-provas .nav-item.-provas {
  color: #80acff;
}

.page.-provas .nav-item.-provas .nav-link {
  border-top-color: inherit;
}

.page.-provas .breadcrumb span {
  color: #80acff;
}

.page.-noticias .nav-item.-noticias {
  color: #bbbbbb;
}

.page.-noticias .nav-item.-noticias .nav-link {
  border-top-color: inherit;
}

.page.-noticias .breadcrumb span {
  color: #bbbbbb;
}

.page.-erratas-e-redificacao .nav-item.-erratas-e-redificacao {
  color: #f86a60;
}

.page.-erratas-e-redificacao .nav-item.-erratas-e-redificacao .nav-link {
  border-top-color: inherit;
}

.page.-erratas-e-redificacao .breadcrumb span {
  color: #f86a60;
}

.btn-loggedin {
  display: none;
}

.main-header.-logged-in .btn-login {
  display: none;
}

.main-header.-logged-in .btn-loggedin {
  display: flex;
  align-itemPixes: center;
  color: #162882;
}

.main-header.-logged-in .btn-loggedin img {
  margin-right: .5rem;
}

.page.-account {
  background-color: #f8f8fa;
}

.page.-account .main-content {
  background: transparent;
}

.page.-account .main-content::before, .page.-account .main-content::after {
  display: table;
  content: " ";
}

.page.-account .main-content::after {
  clear: both;
}

.page.-account .nav-item.-erratas-e-redificacao {
  color: #206cd7;
}

.page.-account .nav-item.-erratas-e-redificacao .nav-link {
  border-bottom-color: inherit;
}

.page.-account .main-navigation .nav-item.-sair .nav-link {
  height: auto;
  line-height: 2rem;
  border: 0;
  padding: 0 1rem;
  border-radius: 4px;
  color: #fd7a0f;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 2px #fd7a0f;
  box-shadow: inset 0 0 0 2px #fd7a0f;
}

.page.-account .main-navigation .nav-item.-sair .nav-link:hover {
  color: white;
  background-color: #fd7a0f;
}

.page.-account .breadcrumb span {
  color: #162882;
}

.page.-account .page-title {
  padding-top: 2rem;
  background: transparent;
}

.page.-account .page-title .subtitle {
  font-size: 1.15rem;
  font-weight: 200;
}

.page.-account .page-title .container:last-child::after {
  background-color: #fd7a0f;
}

.page.-account .section {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 0) and (max-width: 576px) {
  .page.-account .section {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .page.-account .section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 993px) {
  .page.-account .section {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.page.-account .btn-planos,
.page.-account .float-button-whatsapp,
.page.-account .institucional-bar {
  display: none;
}

.page.-account .btn-menu-account {
  color: #f8f8fa;
  background: transparent;
  display: flex;
  align-itemPixes: center;
  margin-left: 1rem;
}

.page.-account .btn-menu-account:hover {
  background-color: #f8f8fa;
  color: #162882;
}

.page.-account .btn-menu-account .fa {
  font-size: 1.75rem;
  margin-right: 0.5rem;
}

.page.-account .header-bottom-bar {
  box-shadow: none;
}

.page.-account .main-navigation .nav-link {
  font-size: 13px;
  padding: 0;
}

.page.-account .main-navigation .nav-link .fa {
  display: none;
}

.menu-ecommerce strong {
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product .variations .variation {
  position: relative;
  margin-bottom: 2rem;
  padding-left: 0px;
  width: 100%;
}

.product .variations .variation label {
  padding-left: 34px;
}

.social-list-mobile {
  display: inline-block;
  width: 160px;
  text-align: center;
}

.social-list-mobile li {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin: 0 6px;
}

.social-list-mobile li a {
  font-size: 18px;
  color: #172983;
}

@media only screen and (max-width: 480px) {
  .menu-ecommerce strong {
    max-width: 70px;
  }
}

.professor-item {
  padding: 1.5rem;
}

.professor {
  background-color: white;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025), 0 5px 5px -3px rgba(248, 186, 186, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.4s cubic-bezier(0.16, 0.56, 0.17, 0.999);
  padding: 1rem;
  cursor: pointer;
}

.professor:hover {
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05), 0 5px 5px -3px rgba(248, 186, 186, 0.08);
}

.professor:hover .embed-responsive::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  font-family: 'FontAwesome';
  font-size: 3rem;
  text-rendering: auto;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f002";
  z-index: 10;
  transform: translateX(-50%) translateY(-50%);
}

.professor:hover .embed-responsive .professor-img {
  opacity: 0.5;
}

.professor .embed-responsive {
  background-color: #162882;
}

.professor .embed-responsive .professor-img {
  width: 200px;
  height: 200px;
}

.professor .professor-caption {
  margin-bottom: 1rem;
}

.professor .professor-title {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.rounded-circle {
  width: 180px;
  height: 179px;
  left: 8px;
}

/* Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap */
/* Small devices (landscape phones, 576px and up) */
/* Medium devices (tablets, 768px and up) */
/* Large devices (desktops, 992px and up) */
/* Extra large devices (large desktops, 1200px and up) */
/* Extra small devices (portrait phones, less than 576px) */
/* Small devices (landscape phones, less than 768px) */
/* Medium devices (tablets, less than 992px) */
/* Large devices (desktops, less than 1200px) */
/* Extra small devices (portrait phones, less than 576px) */
/* Small devices (landscape phones, 576px and up) */
/* Medium devices (tablets, 768px and up) */
/* Large devices (desktops, 992px and up) */
/* Extra large devices (large desktops, 1200px and up) */
