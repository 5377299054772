$c-main: #162882 !default;
// $c-contrast: #ec7829 !default;
$c-contrast: #fd7a0f !default;
$c-alt: #206cd7 !default;
$c-green: #0db870 !default;
$c-red: #f12b2b !default;
$c-light: #f8f8fa !default;
// $c-light: #f8f8f8 !default;
$c-white: white !default;
$c-black: black !default;
$c-mutted: #aaa !default;
$c-grey: #e5e5e5 !default;

$c-livros: #d667c8 !default;
$c-questoes: #ffc965 !default;
$c-destaques: #ffda95 !default;
$c-noticias: #bbbbbb !default;
$c-depoimentos: #c3c6e9 !default;
$c-cursos: #0fb970 !default;
$c-cadernos-de-questoes: #7a000d !default;
$c-erratas: #f86a60 !default;
$c-apostilas: #fd8f36 !default;
$c-provas: #80acff !default;

$fs-section-title: 38px;
$fs-section-subtitle: 18px;